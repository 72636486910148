import { SortTimesPlusRedemption } from 'components/UserInfo/MoreData/TimesPlusRedemption/types'

export const sortTimesPlusRedemption: SortTimesPlusRedemption = (
  { redemptionDate: firstRedemptionDate },
  { redemptionDate: secondRedemptionDate }
) => {
  const firstRedemptionDateTimestamp = new Date(firstRedemptionDate).getTime()
  const secondRedemptionDateTimestamp = new Date(secondRedemptionDate).getTime()

  return secondRedemptionDateTimestamp - firstRedemptionDateTimestamp
}
