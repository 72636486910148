import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FullRoutesToPageNames, Routes } from 'routes/constants'
import {
  PAGE_SITE_NAME,
  PAGE_RESTRICTIONS,
  PAGE_SITE_REGION
} from 'components/common/Analytics/constants'

export const Analytics: React.FC = () => {
  const location = useLocation()
  const { user } = useAuth0()
  const userId = user?.userProfile?.user_id

  const pageName = FullRoutesToPageNames[location.pathname as Routes]

  useEffect(() => {
    // @ts-ignore
    if (window?.utag?.view && userId && pageName) {
      const payload = {
        page_name: pageName,
        page_section: pageName,
        page_type: pageName,
        page_site_name: PAGE_SITE_NAME,
        page_site_region: PAGE_SITE_REGION,
        page_restrictions: PAGE_RESTRICTIONS,
        customer_id: userId?.toString()
      }
      // @ts-ignore
      window.utag.view(payload)
    }
  }, [pageName, userId])

  return null
}
