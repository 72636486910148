import React from 'react'
import ActivityCalendarHeader from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarHeader'
import ActivityCalendarBody from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody'
import styles from './styles.module.css'

const ActivityCalendar: React.FC = () => {
  return (
    <div className={styles.activityCalendar}>
      <ActivityCalendarHeader />
      <ActivityCalendarBody />
    </div>
  )
}

export default ActivityCalendar
