import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Modal, Button, Typography, Input } from 'antd'
import classNames from 'classnames'
import { createUser } from 'redux/actions/admin'
import { validateEmail } from 'components/Admin/CreteUser/utils'
import SelectRole from 'components/Admin/SelectRole'
import { Roles } from 'components/Admin/constants'
import ModalFooter from 'components/Admin/ModalFooter'
import ModalBody from 'components/common/ModalBody'
import styles from './styles.module.css'

const { Text } = Typography

const CreateUser: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [emailValidationError, setEmailValidationError] = useState('')
  const [role, setRole] = useState<Roles>(Roles.User)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const buttonText = formatMessage({
    id: 'component.admin.create.button'
  })

  const modalTitle = formatMessage({
    id: 'component.admin.create.modal.title'
  })

  const modalCancelButton = formatMessage({
    id: 'component.admin.create.modal.cancel'
  })

  const modalCreateButton = formatMessage({
    id: 'component.admin.create.modal.create'
  })

  const modalEmail = formatMessage({
    id: 'component.admin.create.modal.email'
  })

  const modalRole = formatMessage({
    id: 'component.admin.create.modal.role'
  })

  const isCreateDisabled = !email.length

  const onClick = useCallback(() => setIsModalOpen(true), [])

  const onCancel = useCallback(() => {
    setIsModalOpen(false)
    setEmail('')
    setEmailValidationError('')
    setRole(Roles.User)
  }, [])

  const onCreate = useCallback(() => {
    const errorId = validateEmail(email)

    if (errorId) {
      return setEmailValidationError(
        formatMessage({
          id: errorId
        })
      )
    }

    const isAdmin = role === Roles.Admin
    dispatch(createUser({ email: email.trim(), isAdmin }))
    onCancel()
  }, [email, role, dispatch, onCancel, formatMessage])

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    []
  )

  return (
    <>
      <Button className={styles.button} onClick={onClick} type="primary">
        {buttonText}
      </Button>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onCancel={onCancel}
        footer={
          <ModalFooter
            onCancelClick={onCancel}
            onOkClick={onCreate}
            cancelLabel={modalCancelButton}
            okLabel={modalCreateButton}
            isOkDisabled={isCreateDisabled}
            isCancelDanger
          />
        }
      >
        <ModalBody>
          <Text>{modalEmail}</Text>
          <Input
            value={email}
            onChange={onChangeEmail}
            className={styles.input}
            type="text"
          />
          {emailValidationError && (
            <Text className={styles.errorMessage}>{emailValidationError}</Text>
          )}
          <Text>{modalRole}</Text>
          <SelectRole
            role={role}
            setRole={setRole}
            className={classNames(styles.input, styles.select)}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default CreateUser
