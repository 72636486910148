import { all, takeEvery, put, call } from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { handleError } from 'redux/actions/errors'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { FETCH_TIMES_PLUS_OFFERS } from 'redux/actions/userInfo/constants'
import { consumeTimesPlusOffers } from 'redux/actions/userInfo'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchTimesPlusOffers() {
  try {
    yield put(startLoading(Loaders.MoreDataLifeTimeOffers))

    const { data } = yield call(api.getTimesPlusOffers)

    if (data?.length) {
      yield put(consumeTimesPlusOffers(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.MoreDataLifeTimeOffers))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* timesPlusOffers() {
  yield all([takeEvery(FETCH_TIMES_PLUS_OFFERS, handleFetchTimesPlusOffers)])
}
