import { Typography } from 'antd'
import classNames from 'classnames'
import CheckSvg from 'components/common/Flag/Svg/checkSvg'
import CrossSvg from 'components/common/Flag/Svg/crossSvg'
import styles from './styles.module.css'

const { Text } = Typography

type Props = {
  isChecked: boolean
  label?: string
  extraClassName?: string
}

const Flag: React.FC<Props> = ({ label, isChecked, extraClassName }) => {
  return (
    <div className={classNames(styles.flag, extraClassName)}>
      <div className={styles.color}>
        {isChecked ? <CheckSvg /> : <CrossSvg />}
      </div>
      {label && <Text className={styles.label}>{label}</Text>}
    </div>
  )
}

export default Flag
