import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Layout, Typography } from 'antd'
import { FullRoutesToPageNames } from 'routes/constants'
import Menu from 'components/common/LayoutContent/Header/Menu'
import styles from './styles.module.css'

const { Title } = Typography
const { Header: AntHeader } = Layout

const Header: React.FC = () => {
  const { pathname } = useLocation()
  const { formatMessage } = useIntl()
  const title = formatMessage({
    id: `component.${FullRoutesToPageNames[pathname]}.title`
  })

  return (
    <AntHeader className={styles.header}>
      <Title className={styles.title} level={3}>
        {title}
      </Title>

      <Menu />
    </AntHeader>
  )
}

export default Header
