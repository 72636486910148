import { RootState } from 'redux/types/store'
import { SectionTypes, ArticleType } from 'redux/reducers/home/types'

export const getSectionArticles = (
  state: RootState,
  section: SectionTypes
): ArticleType[] => state.home.articles[section]

export const getSelectedSectionFilterValue = (state: RootState): string =>
  state.home.sectionFilterValue

export const getSelectedLookBackDate = (state: RootState): string =>
  state.home.lookBackDate
