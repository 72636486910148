import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Alert } from 'antd'
import { getCustomerStats } from 'redux/selectors/userInfo'
import Stat from 'components/common/Stat'
import { convertTenureMonthsToYears } from 'components/UserInfo/Dashboard/SubInfo/utils'
import Flag from 'components/common/Flag'
import ChurnScore from 'components/UserInfo/Dashboard/SubInfo/ChurnScore'
import { formatDate } from 'utils/date'
import styles from './styles.module.css'

const PriceIncrease: React.FC = () => {
  const {
    mpCode,
    tenureMonths,
    churnBuckets,
    churnSegmentExplanation,
    appDownloadedFlag,
    notificationDate,
    migrationDate,
    newPackName,
    newPrice,
    newMpCode,
    saveTools,
    timesPlusRedemptionFlag
  } = useSelector(getCustomerStats)

  const { formatMessage } = useIntl()

  const tenure = convertTenureMonthsToYears(tenureMonths)

  const combineMpCode = newMpCode ? `${mpCode} -> ${newMpCode}` : mpCode

  const priceIncreaseAlertLabel = formatMessage({
    id: 'component.user-info.customerStats.priceIncreaseAlert'
  })

  const priceIncreaseAlert = `${priceIncreaseAlertLabel} ${saveTools}`

  const formatterMigrationDate = formatDate(migrationDate)
  const formatterNotificationDate = formatDate(notificationDate)

  const tenureLabel = formatMessage({
    id: 'component.user-info.customerStats.tenure'
  })

  const notificationDateLabel = formatMessage({
    id: 'component.user-info.customerStats.notificationDate'
  })

  const migrationDateLabel = formatMessage({
    id: 'component.user-info.customerStats.migrationDate'
  })

  const newPackNameLabel = formatMessage({
    id: 'component.user-info.customerStats.newPackName'
  })

  const newPackPriceLabel = formatMessage({
    id: 'component.user-info.customerStats.newPackPrice'
  })

  const mpCodeLabel = formatMessage({
    id: 'component.user-info.customerStats.mpCode'
  })

  const appDownloadedFlagLabel = formatMessage({
    id: 'component.user-info.customerStats.appDownloaded'
  })

  const timesPlusRedemptionFlagLabel = formatMessage({
    id: 'component.user-info.customerStats.timesPlusEngagement'
  })

  return (
    <div className={styles.container}>
      <Alert message={priceIncreaseAlert} type="error" />

      <div className={styles.stat}>
        <Stat label={tenureLabel} value={tenure || ''} />
        <Stat label={mpCodeLabel} value={combineMpCode} />
      </div>
      <div className={styles.stat}>
        <Stat label={notificationDateLabel} value={formatterNotificationDate} />
        <Stat label={migrationDateLabel} value={formatterMigrationDate} />
      </div>

      <div className={styles.stat}>
        <Stat label={newPackNameLabel} value={newPackName} />
        <Stat label={newPackPriceLabel} value={newPrice} />
      </div>

      <Flag
        label={timesPlusRedemptionFlagLabel}
        isChecked={timesPlusRedemptionFlag}
        extraClassName={styles.flag}
      />
      <Flag
        label={appDownloadedFlagLabel}
        isChecked={appDownloadedFlag}
        extraClassName={styles.flag}
      />
      <ChurnScore value={churnBuckets} description={churnSegmentExplanation} />
    </div>
  )
}

export default PriceIncrease
