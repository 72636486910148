import { SectionTypes } from 'redux/reducers/home/types'

export const TOOLTIP_SECTIONS = {
  [SectionTypes.Engaging]:
    'We predict how long users will spend on our articles. The most engaging articles are those which users spend the most time on relative to our prediction',
  [SectionTypes.Popular]:
    'The most read articles by number of reads in the past 6 days',
  [SectionTypes.Commented]:
    'The most commented articles by number of comments in the past 6 days',
  [SectionTypes.Trending]: null
}
