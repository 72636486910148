import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Select } from 'antd'
import classNames from 'classnames'
import { AllRoles, Roles } from 'components/Admin/constants'
import styles from './styles.module.css'

const { Option } = Select

interface Props {
  role: Roles
  setRole: (newRole: Roles) => void
  className?: string
}

const SelectRole: React.FC<Props> = ({ role, setRole, className }) => {
  const { formatMessage } = useIntl()
  const onChange = useCallback((newRole: Roles) => setRole(newRole), [setRole])

  const renderOptions = useCallback(
    (optionRole: Roles) => {
      const label = formatMessage({
        id: `component.admin.select.${optionRole}`
      })
      return (
        <Option value={optionRole} key={optionRole}>
          {label}
        </Option>
      )
    },
    [formatMessage]
  )

  return (
    <Select
      value={role}
      onChange={onChange}
      className={classNames(styles.select, className)}
    >
      {AllRoles.map(renderOptions)}
    </Select>
  )
}

export default SelectRole
