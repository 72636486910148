import {
  defaultLookBackDaysValue,
  defaultSectionFilterValue
} from 'components/Home/Header/constants'
import { subDays } from 'date-fns'
import { formatDate } from 'utils/date'
import { HomeStateType } from 'redux/reducers/home/types'

export const HomeInitialState: HomeStateType = {
  articles: {
    popular: [],
    commented: [],
    trending: [],
    engaging: []
  },
  sectionFilterValue: defaultSectionFilterValue,
  lookBackDate: formatDate(subDays(new Date(), defaultLookBackDaysValue))
}
