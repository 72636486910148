import { Divider } from 'antd'

interface Props {
  children?: React.ReactNode
}

const ModalBody: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Divider />
      {children}
      <Divider />
    </>
  )
}

export default ModalBody
