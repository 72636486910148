export default {
  'component.admin.title': 'Admin Panel',
  'component.admin.table.email': 'Email',
  'component.admin.table.role': 'Role',
  'component.admin.table.loginsCount': 'Login Count',
  'component.admin.table.latestLogin': 'Latest Login',
  'component.admin.table.cpnSearchCount': 'CPN  Search Count',
  'component.admin.table.delete': 'Delete',
  'component.admin.table.edit': 'Edit',
  'component.admin.create.button': 'Create User',
  'component.admin.create.modal.title': 'Create User',
  'component.admin.create.modal.cancel': 'Cancel',
  'component.admin.create.modal.create': 'Create',
  'component.admin.create.modal.email': 'Email',
  'component.admin.create.modal.role': 'Role',
  'component.admin.create.modal.email.error.invalid': 'Invalid email address',
  'component.admin.create.modal.email.error.domain':
    'Invalid email domain name, allowed domains are: news.co.uk, gfm.co.uk, interactcc.com',
  'component.admin.header.title': 'Users',
  'component.admin.select.user': 'User',
  'component.admin.select.admin': 'Admin',
  'component.admin.badge.user': 'User',
  'component.admin.badge.admin': 'Admin',
  'component.admin.delete.modal.text': ' Are you sure want to delete user',
  'component.admin.delete.modal.cancel': 'Cancel',
  'component.admin.delete.modal.delete': 'Delete',
  'component.admin.delete.modal.title': 'Delete User',
  'component.admin.edit.modal.title': 'Edit User',
  'component.admin.edit.modal.cancel': 'Cancel',
  'component.admin.edit.modal.save': 'Save',
  'component.admin.edit.modal.email': 'Email',
  'component.admin.edit.modal.role': 'Role',
  'component.admin.search.email': 'Search email',
  'component.admin.search.search': 'Search',
  'component.admin.search.reset': 'Reset'
}
