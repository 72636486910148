import { Button } from 'antd'

type Props = {
  cancelLabel: string
  okLabel: string
  onCancelClick: () => void
  onOkClick: () => void
  okExtraClassNames?: string
  isOkDisabled?: boolean
  isOkDanger?: boolean
  isCancelDanger?: boolean
}

const ModalFooter: React.FC<Props> = ({
  cancelLabel,
  okLabel,
  okExtraClassNames,
  onCancelClick,
  onOkClick,
  isOkDisabled,
  isOkDanger,
  isCancelDanger
}) => {
  return (
    <>
      <Button
        onClick={onCancelClick}
        danger={isCancelDanger}
        type="primary"
        key="cancel"
        ghost
      >
        {cancelLabel}
      </Button>
      <Button
        onClick={onOkClick}
        className={okExtraClassNames}
        disabled={isOkDisabled}
        danger={isOkDanger}
        type="primary"
        key="ok"
      >
        {okLabel}
      </Button>
    </>
  )
}

export default ModalFooter
