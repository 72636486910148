import { Typography } from 'antd'
import { useIntl } from 'react-intl'
import styles from './styles.module.css'

const { Text } = Typography

type Payload = {
  payload?: {
    name?: string
    pagesPerDay?: string
  }
}

type Props = {
  payload?: Payload[]
}

const CustomTooltip: React.FC<Props> = ({ payload }) => {
  const { formatMessage } = useIntl()

  const dateLabel = formatMessage({
    id: 'component.user-info.customerStats.dailyStats.tooltip.date'
  })

  const sessionsPerDayLabel = formatMessage({
    id: 'component.user-info.customerStats.dailyStats.tooltip.pagesPerDay'
  })

  const date = payload?.[0]?.payload?.name
  const pagesPerDay = payload?.[0]?.payload?.pagesPerDay

  return (
    <div className={styles.tooltip}>
      <Text className={styles.text}>
        {dateLabel}
        {date}
      </Text>
      <Text className={styles.text}>
        {sessionsPerDayLabel}
        {pagesPerDay}
      </Text>
    </div>
  )
}

export default CustomTooltip
