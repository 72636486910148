import api from 'api'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from 'redux/actions/errors'
import { SAVE_LOGIN_DATA } from 'redux/actions/login/constants'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleSaveLoginData() {
  try {
    yield call(api.saveLoginData)
  } catch (err: any) {
    yield put(handleError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* saveLogin() {
  yield all([takeEvery(SAVE_LOGIN_DATA, handleSaveLoginData)])
}
