import classNames from 'classnames'
import { Empty } from 'antd'
import styles from './styles.module.css'

export type Props = {
  text?: string
  extraClassnames?: string
}
const NoData: React.FC<Props> = ({ text, extraClassnames }) => (
  <Empty
    className={classNames(styles.wrapper, extraClassnames)}
    description={text}
  />
)

export default NoData
