import {
  SectionLikesStateType,
  SECTION_IDS
} from 'redux/reducers/sections-likes/types'

export const sectionLikesInitialState: SectionLikesStateType = {
  [SECTION_IDS.DeviceUsage]: false,
  [SECTION_IDS.ReaderEngagement]: false,
  [SECTION_IDS.SingOnArticle]: false,
  [SECTION_IDS.SubInfo]: false,
  [SECTION_IDS.MostRead]: false
}
