export enum NotificationsType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface Notification {
  messageId: string
  isOpen: boolean
  type: NotificationsType
}

export interface NotificationsStateType {
  notification: Notification
}
