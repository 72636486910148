import { Typography } from 'antd'
import { useIntl } from 'react-intl'
import DateFilter from 'components/Home/Header/DateFilter'
import SectionFilter from 'components/Home/Header/SectionFilter'
import styles from './styles.module.css'

const { Title } = Typography

const Header: React.FC = () => {
  const { formatMessage } = useIntl()

  const subTitle = formatMessage({
    id: 'component.home.subtitle'
  })

  return (
    <div className={styles.wrapper}>
      <Title level={3}>{subTitle}</Title>
      <div>
        <DateFilter />
        <SectionFilter />
      </div>
    </div>
  )
}

export default Header
