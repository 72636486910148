import { TableUsers } from 'components/Admin/UserTable/types'
import { Roles } from 'components/Admin/constants'

type SortStrings = (a: TableUsers, b: TableUsers) => number

export const sortEmails: SortStrings = (a, b) => {
  if (a.email && b.email) {
    return a?.email.localeCompare(b.email)
  }

  return 0
}

export const sortLoginsCount: SortStrings = (a, b) => {
  if (a.loginsCount > b.loginsCount) {
    return -1
  }

  return 1
}

export const sortCpnSearchCount: SortStrings = (a, b) => {
  if (a.cpnSearchCount > b.cpnSearchCount) {
    return -1
  }

  return 1
}

export const sortLatestLogin: SortStrings = (a, b) => {
  if (a.latestLoginTimestamp > b.latestLoginTimestamp) {
    return -1
  }

  return 1
}

export const sortRoles: SortStrings = (a) => {
  if (a.role === Roles.Admin) {
    return -1
  }

  return 1
}
