import { MapAndSortDeviceData } from 'components/UserInfo/Dashboard/DeviceUsage/types'

export const mapAndSortDeviceData: MapAndSortDeviceData = (device) =>
  device
    ?.map(({ name, count: value }) => ({
      name,
      value
    }))
    .sort(
      ({ value: firstValue }, { value: secondValue }) =>
        secondValue - firstValue
    ) || []
