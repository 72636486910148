import common from 'i18n/en_US/common'
import cpn from 'i18n/en_US/cpn'
import home from 'i18n/en_US/home'
import userInfo from 'i18n/en_US/userInfo'
import admin from 'i18n/en_US/admin'
import notifications from 'i18n/en_US/notifications'

const messages = {
  ...common,
  ...cpn,
  ...home,
  ...userInfo,
  ...admin,
  ...notifications
}

export default messages
