import { useAuth0 } from '@auth0/auth0-react'
import { UserRoles } from 'components/common/hooks/constants'

const useIsAdmin = (): boolean => {
  const { user } = useAuth0()

  const userRole = user?.userProfile?.app_metadata?.contactCentreTool?.role

  return userRole === UserRoles.Admin
}

export default useIsAdmin
