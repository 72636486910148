import { Badge } from 'antd'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import styles from './styles.module.css'

export const AdminBadge: React.FC = () => {
  const { formatMessage } = useIntl()

  const adminLabel = formatMessage({
    id: 'component.admin.badge.admin'
  })

  return (
    <Badge
      count={adminLabel}
      className={classNames(styles.badge, styles.admin)}
    />
  )
}
export const UserBadge: React.FC = () => {
  const { formatMessage } = useIntl()

  const userLabel = formatMessage({
    id: 'component.admin.badge.user'
  })

  return (
    <Badge
      count={userLabel}
      className={classNames(styles.badge, styles.user)}
    />
  )
}
