import { useCallback } from 'react'
import styles from './styles.module.css'

function generatePastDays(): Date[] {
  const days: Date[] = []
  for (let i = 89; i >= 0; i--) {
    const date = new Date()
    date.setDate(date.getDate() - i)
    days.push(date)
  }
  return days
}

const ActivityCalendarHeader: React.FC = () => {
  const pastDays = generatePastDays()
  const renderMonths = useCallback(() => {
    const months: JSX.Element[] = []
    let lastMonth: string | null = null
    let monthStartIndex = 0

    const firstDay = pastDays[0].getDay()
    const offset = firstDay === 0 ? 6 : firstDay - 1

    pastDays.forEach((date, index) => {
      const month = date.toLocaleString('default', { month: 'short' })
      if (month !== lastMonth) {
        if (lastMonth) {
          months.push(
            <div
              key={lastMonth}
              className={styles.month}
              style={{
                gridColumnStart: Math.floor((monthStartIndex + offset) / 7) + 1,
                gridRowStart: 0
              }}
            >
              {lastMonth}
            </div>
          )
        }
        lastMonth = month
        monthStartIndex = index
      }
    })

    if (lastMonth) {
      months.push(
        <div
          key={lastMonth}
          className={styles.month}
          style={{
            gridColumnStart: Math.floor((monthStartIndex + offset) / 7) + 1,
            gridRowStart: 0
          }}
        >
          {lastMonth}
        </div>
      )
    }

    return months
  }, [pastDays])

  return <div className={styles.header}>{renderMonths()}</div>
}

export default ActivityCalendarHeader
