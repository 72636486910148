import { useState } from 'react'
import { Segmented } from 'antd'

import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { getCustomerStats } from 'redux/selectors/userInfo'
import { Loaders } from 'redux/reducers/loaders/types'
import { getIsLoading } from 'redux/selectors/loaders'
import Stat from 'components/common/Stat'
import Box from 'components/common/Box'
import CustomerDailyStats from 'components/UserInfo/Dashboard/ReaderEngagement/CustomerDailyStats'
import ActivityCalendar from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar'
import { SECTION_IDS } from 'redux/reducers/sections-likes/types'
import styles from './styles.module.css'

const ReaderEngagement: React.FC = () => {
  const [value, setValue] = useState<string | number>('engagement')
  const { formatMessage } = useIntl()

  const customerData = useSelector((state: RootState) =>
    getCustomerStats(state)
  )

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.UserInfo)
  )

  const averageRead =
    customerData.articlesRead && Math.round(customerData.articlesRead / 90)

  const totalReadLabel = formatMessage({
    id: 'component.user-info.customerStats.totalRead'
  })

  const averageReadLabel = formatMessage({
    id: 'component.user-info.customerStats.averageRead'
  })

  const commentsLabel = formatMessage({
    id: 'component.user-info.customerStats.comments'
  })

  const puzzlesLabel = formatMessage({
    id: 'component.user-info.customerStats.puzzles'
  })

  const isEngagementActive = value === 'engagement'

  return (
    <Box
      sectionId={SECTION_IDS.ReaderEngagement}
      shouldRenderLikeSection
      isLoading={isLoading ? false : false}
      loaderExtraClassName={styles.loaderExtraClassName}
      extraWrapperClassNames={styles.extraWrapperClassNames}
      extraBodyClassNames={styles.extraBodyClassNames}
      extraTitleClassNames={styles.extraTitleClassNames}
      customHeaderComponent={
        <Segmented
          options={['engagement', 'vouchers']}
          value={value}
          onChange={setValue}
          className={styles.segmented}
        />
      }
    >
      {isEngagementActive ? <CustomerDailyStats /> : <ActivityCalendar />}

      <div className={styles.stats}>
        <Stat
          label={totalReadLabel}
          value={customerData.articlesRead}
          extraClassName={styles.stat}
          extraValueClassName={styles.statValue}
          extraLabelClassName={styles.statLabel}
          shouldRenderEmptyValue
        />
        <Stat
          label={averageReadLabel}
          value={averageRead}
          extraClassName={styles.stat}
          extraValueClassName={styles.statValue}
          extraLabelClassName={styles.statLabel}
          shouldRenderEmptyValue
        />
        <Stat
          label={commentsLabel}
          value={customerData.comments}
          extraClassName={styles.stat}
          extraValueClassName={styles.statValue}
          extraLabelClassName={styles.statLabel}
          shouldRenderEmptyValue
        />
        <Stat
          label={puzzlesLabel}
          value={customerData.puzzles}
          extraClassName={styles.stat}
          extraValueClassName={styles.statValue}
          extraLabelClassName={styles.statLabel}
          shouldRenderEmptyValue
        />
      </div>
    </Box>
  )
}

export default ReaderEngagement
