import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Table } from 'antd'
import Box from 'components/common/Box'
import { SECTION_IDS } from 'redux/reducers/sections-likes/types'
import { getCustomerStats } from 'redux/selectors/userInfo'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { MostRead as MostReadType } from 'redux/reducers/userInfo/types'
import { MOST_READ_ODER_TYPE } from 'components/UserInfo/Dashboard/MostRead/constants'
import styles from './styles.module.css'

const MostRead: React.FC = () => {
  const { mostRead } = useSelector(getCustomerStats)

  const { formatMessage } = useIntl()

  const columns = [
    {
      title: formatMessage({
        id: 'component.user-info.mostRead.type'
      }),
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: formatMessage({
        id: 'component.user-info.mostRead.value'
      }),
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: formatMessage({
        id: 'component.user-info.mostRead.views'
      }),
      dataIndex: 'views',
      key: 'views'
    }
  ]

  const scrollConfig =
    mostRead.length > 4
      ? {
          y: 190
        }
      : {}

  const sortedMostRead: MostReadType[] = useMemo(
    () =>
      [...mostRead].sort(
        (a, b) => MOST_READ_ODER_TYPE[a.type] - MOST_READ_ODER_TYPE[b.type]
      ),
    [mostRead]
  )

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.UserInfo)
  )

  const shouldRenderNoData = !mostRead.length

  return (
    <Box
      sectionId={SECTION_IDS.MostRead}
      isLoading={isLoading}
      extraWrapperClassNames={styles.extraWrapperClassNames}
      noDataExtraClassName={styles.noDataExtraClassName}
      shouldRenderNoData={shouldRenderNoData}
      shouldRenderLikeSection
    >
      <Table
        columns={columns}
        dataSource={sortedMostRead}
        pagination={false}
        scroll={scrollConfig}
        size="small"
      />
    </Box>
  )
}

export default MostRead
