import WeekdaysSidebar from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/WeekdaysSidebar'
import DaysBoxes from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/DaysBoxes'
import styles from './styles.module.css'

const ActivityCalendarBody: React.FC = () => {
  return (
    <div className={styles.body}>
      <WeekdaysSidebar />
      <DaysBoxes />
    </div>
  )
}

export default ActivityCalendarBody
