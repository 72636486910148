import { Button } from 'antd'
import { useIntl } from 'react-intl'

type Props = {
  onSave: () => void
  onCancel: () => void
  isSaveDisabled: boolean
}

const Footer: React.FC<Props> = ({ onSave, onCancel, isSaveDisabled }) => {
  const { formatMessage } = useIntl()

  const saveLabel = formatMessage({
    id: 'component.common.modal.save'
  })

  const cancelLabel = formatMessage({
    id: 'component.common.modal.cancel'
  })

  return (
    <>
      <Button onClick={onCancel}>{cancelLabel}</Button>
      <Button type="primary" onClick={onSave} disabled={isSaveDisabled}>
        {saveLabel}
      </Button>
    </>
  )
}

export default Footer
