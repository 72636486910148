import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { resetNotificationState } from 'redux/actions/notifications'
import { getNotification } from 'redux/selectors/notifications'

const useNotification = (): void => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { isOpen, messageId, type } = useSelector(getNotification)

  const errorMessageLabel = formatMessage({
    id: messageId
  })

  useEffect(() => {
    if (isOpen) {
      toast[type](errorMessageLabel)
      dispatch(resetNotificationState())
    }
  }, [dispatch, errorMessageLabel, isOpen, type])
}

export default useNotification
