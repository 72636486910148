import React from 'react'
import { Spin } from 'antd'
import styles from './styles.module.css'
import classNames from 'classnames'

type Props = {
  extraClassnames?: string
}

const Loader: React.FC<Props> = ({ extraClassnames }) => (
  <div className={classNames(styles.wrapper, extraClassnames)}>
    <Spin size="large" />
  </div>
)

export default Loader
