import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import api from 'api'
import { handleError } from 'redux/actions/errors'
import { FETCH_DEVICE_USAGE } from 'redux/actions/userInfo/constants'
import { consumeDeviceUsage } from 'redux/actions/userInfo'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { getCpn } from 'redux/selectors/cpn'
import { mapDeviceUsageData } from 'redux/mappers/map-device-usage-data'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchDeviceUsage() {
  try {
    yield put(startLoading(Loaders.UserInfoDeviceUsage))
    const cpn: string = yield select(getCpn)

    const { data } = yield call(api.getDeviceUsage, {
      queryParams: { cpn }
    })

    if (data?.length) {
      const mappedData = mapDeviceUsageData(data)

      yield put(consumeDeviceUsage(mappedData))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.UserInfoDeviceUsage))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* deviceUsage() {
  yield all([takeEvery(FETCH_DEVICE_USAGE, handleFetchDeviceUsage)])
}
