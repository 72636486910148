import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { sectionLikesInitialState } from 'redux/reducers/sections-likes/constants'
import { SaveLikeSection } from 'redux/actions/sections-likes/types'

export const sectionLikesSlice = createSlice({
  name: ReducersType.Likes,
  initialState: sectionLikesInitialState,
  reducers: {
    saveLikeSection: (
      state,
      { payload: { section } }: PayloadAction<SaveLikeSection>
    ) => ({
      ...state,
      [section]: true
    }),
    resetLikes: () => sectionLikesInitialState
  }
})
