import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Section from 'components/Home/Sections/Section'
import { sections } from 'components/Home/Sections/constants'
import { SectionTypes } from 'redux/reducers/home/types'
import { fetchSectionTopArticles, resetTopArticles } from 'redux/actions/home'
import styles from './styles.module.css'
import {
  getSelectedLookBackDate,
  getSelectedSectionFilterValue
} from 'redux/selectors/home'

const Sections: React.FC = () => {
  const dispatch = useDispatch()
  const selectedLookBackDate = useSelector(getSelectedLookBackDate)
  const selectedSectionFilterValue = useSelector(getSelectedSectionFilterValue)

  const renderSections = useCallback((section: SectionTypes) => {
    return <Section key={section} section={section} />
  }, [])

  useEffect(() => {
    dispatch(fetchSectionTopArticles())

    return () => {
      dispatch(resetTopArticles())
    }
  }, [dispatch, selectedLookBackDate, selectedSectionFilterValue])

  return <div className={styles.wrapper}>{sections.map(renderSections)}</div>
}

export default Sections
