import { Divider, Typography } from 'antd'
import { useIntl } from 'react-intl'
import CreateUser from 'components/Admin/CreteUser'
import styles from './styles.module.css'

const { Title } = Typography

const Header: React.FC = () => {
  const { formatMessage } = useIntl()

  const title = formatMessage({
    id: 'component.admin.header.title'
  })

  return (
    <>
      <div className={styles.header}>
        <Title level={3}>{title}</Title>
        <CreateUser />
      </div>
      <Divider className={styles.divider} />
    </>
  )
}

export default Header
