import { useCallback } from 'react'
import { Typography } from 'antd'
import { navigateExternal } from 'utils/navigate-external'
import { SectionTypes } from 'redux/reducers/home/types'
import DisplaySectionData from 'components/Home/Sections/Section/Article/DisplayData'
import styles from './styles.module.css'

const { Title } = Typography

export type Props = {
  section: SectionTypes
  contentUrl: string
  contentImgUrl: string
  contentHeadline: string
  articleComments: number
  pageViews: number
}

const Article: React.FC<Props> = ({
  section,
  contentUrl,
  contentImgUrl,
  contentHeadline,
  articleComments,
  pageViews
}) => {
  const openArticleUrl = useCallback(() => {
    navigateExternal(contentUrl)
  }, [contentUrl])

  const shouldRenderPopularStats = section === SectionTypes.Popular

  const shouldRenderCommentedStats = section === SectionTypes.Commented

  return (
    <div onClick={openArticleUrl} className={styles.article}>
      <img className={styles.img} src={contentImgUrl} alt={section} />
      <div className={styles.content}>
        <Title level={5}>{contentHeadline}</Title>
        {shouldRenderCommentedStats && (
          <DisplaySectionData value={articleComments} />
        )}
        {shouldRenderPopularStats && <DisplaySectionData value={pageViews} />}
      </div>
    </div>
  )
}

export default Article
