export enum Loaders {
  Home = 'home',
  Cpn = 'Cpn',
  UserInfo = 'userInfo',
  MoreDataLifeTimeOffers = 'moreDataLifeTimeOffers',
  MoreDataTimesPlusRedemption = 'moreDataTimesPlusRedemption',
  UserInfoDeviceUsage = 'userInfoDeviceUsage',
  Admin = 'admin'
}

export type LoadersStateType = Record<Loaders, boolean>
