import { User, UsersStats } from 'redux/actions/admin/types'

export default (users: User[], usersStats: UsersStats[]): User[] => {
  if (!usersStats.length) {
    return users
  }

  if (!users.length) {
    return []
  }

  return users.map((user) => {
    const userStats = usersStats.find(
      (cpnSearch: UsersStats) => cpnSearch.email === user.email
    )

    return {
      ...user,
      cpnSearchCount: userStats?.cpnSearches || 0,
      loginsCount: userStats?.loginCount || 0,
      latestLoginTimestamp: userStats?.latestLoginTimestamp || 0
    }
  })
}
