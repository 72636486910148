import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isAfter, format } from 'date-fns'
import { convertDateStringToDate } from 'utils/date'
import { UK_DATE_FORMAT } from 'utils/constants'
import { getSelectedLookBackDate } from 'redux/selectors/home'
import { resetSectionFilterValue, selectLookBackDate } from 'redux/actions/home'
import DatePicker from 'components/common/DatePicker'
import { DATE_PICKER_UK_DATE_FORMAT } from 'components/Home/Header/DateFilter/constants'
import styles from './styles.module.css'

const DateFilter: React.FC = () => {
  const dispatch = useDispatch()
  const selectedLookBackDate = useSelector(getSelectedLookBackDate)

  const onChange = useCallback(
    (date: any) => dispatch(selectLookBackDate(format(date, UK_DATE_FORMAT))),
    [dispatch]
  )

  const disabledDate = useCallback((current: Date) => {
    return current && isAfter(new Date(current), new Date())
  }, [])

  const datePickerValue = useMemo(
    () => convertDateStringToDate(selectedLookBackDate),
    [selectedLookBackDate]
  )

  useEffect(() => {
    return () => {
      dispatch(resetSectionFilterValue())
    }
  }, [dispatch])

  return (
    <DatePicker
      className={styles.datePicker}
      onChange={onChange}
      disabledDate={disabledDate}
      value={datePickerValue}
      allowClear={false}
      format={DATE_PICKER_UK_DATE_FORMAT}
    />
  )
}

export default DateFilter
