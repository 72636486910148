import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Select } from 'antd'
import {
  defaultSectionFilterValue,
  sectionFilterOptions
} from 'components/Home/Header/constants'
import { resetLookBackDate, selectSectionFilterValue } from 'redux/actions/home'
import { SectionFilterOptionType } from 'components/Home/Header/SectionFilter/types'
import styles from './styles.module.css'

const { Option } = Select

const SectionFilter: React.FC = () => {
  const dispatch = useDispatch()
  const onSelectSection = useCallback(
    (value: string) => {
      dispatch(selectSectionFilterValue(value))
    },
    [dispatch]
  )

  const renderOptions = useCallback(
    ({ value, label }: SectionFilterOptionType) => (
      <Option key={value} value={value}>
        {label}
      </Option>
    ),
    []
  )

  useEffect(() => {
    return () => {
      dispatch(resetLookBackDate())
    }
  }, [dispatch])

  return (
    <Select
      defaultValue={defaultSectionFilterValue}
      onChange={onSelectSection}
      className={styles.select}
    >
      {sectionFilterOptions.map(renderOptions)}
    </Select>
  )
}

export default SectionFilter
