import { Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FullRoutes } from 'routes/constants'
import Loader from 'components/common/Content/Loader'

export type Props = {
  children?: React.ReactNode
}

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <Loader />
  }

  return isAuthenticated ? <>{children}</> : <Navigate to={FullRoutes.Login} />
}

export default PrivateRoute
