import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { NotificationsInitialState } from 'redux/reducers/notifications/constants'
import { Notification } from './types'

export const notificationsSlice = createSlice({
  name: ReducersType.Notifications,
  initialState: NotificationsInitialState,
  reducers: {
    setNotification: (state, { payload }: PayloadAction<Notification>) => ({
      ...state,
      notification: payload
    }),
    resetNotificationState: () => NotificationsInitialState
  }
})
