import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Modal, Typography, Input } from 'antd'
import { EditFilled } from '@ant-design/icons'
import { updateUser } from 'redux/actions/admin'
import SelectRole from 'components/Admin/SelectRole'
import { Roles } from 'components/Admin/constants'
import ModalFooter from 'components/Admin/ModalFooter/index'
import ModalBody from 'components/common/ModalBody'
import styles from './styles.module.css'

const { Text } = Typography

type Props = {
  userId: string
  email: string
  isAdmin: boolean
}

const EditUser: React.FC<Props> = ({ userId, email, isAdmin }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentRole = isAdmin ? Roles.Admin : Roles.User
  const [selectedRole, setSelectedRole] = useState<Roles>(currentRole)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const editModalTitle = formatMessage({
    id: 'component.admin.edit.modal.title'
  })

  const modalCancelButton = formatMessage({
    id: 'component.admin.edit.modal.cancel'
  })

  const modalSaveButton = formatMessage({
    id: 'component.admin.edit.modal.save'
  })

  const modalEmail = formatMessage({
    id: 'component.admin.edit.modal.email'
  })

  const modalRole = formatMessage({
    id: 'component.admin.edit.modal.role'
  })

  const onClick = useCallback(() => setIsModalOpen(true), [])

  const onCancel = useCallback(() => setIsModalOpen(false), [])

  const onSave = useCallback(() => {
    dispatch(
      updateUser({
        userId,
        isAdmin: selectedRole === Roles.Admin
      })
    )
    onCancel()
  }, [dispatch, onCancel, selectedRole, userId])

  const isSelectedDisabled = selectedRole === currentRole

  return (
    <>
      <EditFilled onClick={onClick} />
      <Modal
        title={editModalTitle}
        open={isModalOpen}
        onCancel={onCancel}
        footer={
          <ModalFooter
            onOkClick={onSave}
            onCancelClick={onCancel}
            isOkDisabled={isSelectedDisabled}
            cancelLabel={modalCancelButton}
            okLabel={modalSaveButton}
            isCancelDanger
          />
        }
      >
        <ModalBody>
          <Text>{modalEmail}</Text>
          <Input value={email} className={styles.input} type="text" disabled />
          {modalRole}
          <SelectRole
            role={selectedRole}
            setRole={setSelectedRole}
            className={styles.input}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditUser
