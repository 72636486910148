import { createAction } from '@reduxjs/toolkit'
import {
  LIKE_SECTION,
  RESET_LIKES,
  SAVE_LIKE_SECTION
} from 'redux/actions/sections-likes/constants'
import {
  LikeSectionPayload,
  SaveLikeSection
} from 'redux/actions/sections-likes/types'

export const likeSection = createAction<LikeSectionPayload>(LIKE_SECTION)
export const saveLikeSection = createAction<SaveLikeSection>(SAVE_LIKE_SECTION)
export const resetLikes = createAction(RESET_LIKES)
