import { Typography } from 'antd'
import styles from './styles.module.css'

const { Text } = Typography

export type Props = {
  value: string | number
}

const DisplaySectionData: React.FC<Props> = ({ value }) => (
  <Text className={styles.text}>{value}</Text>
)

export default DisplaySectionData
