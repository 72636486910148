import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'antd'
import { formatDistance } from 'date-fns'
import type { TablePaginationConfig } from 'antd/es/table'
import { fetchUsers } from 'redux/actions/admin'
import { getUsers } from 'redux/selectors/admin'
import { Roles } from 'components/Admin/constants'
import { TableUsers } from 'components/Admin/UserTable/types'
import useColumns from 'components/Admin/UserTable/useColumns'
import { defaultPageSize } from 'components/Admin/UserTable/constants'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'

const UsersTable: React.FC = () => {
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: defaultPageSize
  })

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.Admin)
  )
  const users = useSelector(getUsers)
  const dispatch = useDispatch()

  const dataSource: TableUsers[] = useMemo(
    () =>
      users.map(
        ({
          isAdmin,
          email,
          id,
          loginsCount,
          cpnSearchCount,
          latestLoginTimestamp
        }) => {
          const latestLogin = latestLoginTimestamp
            ? formatDistance(latestLoginTimestamp, new Date())
            : '-'

          return {
            email,
            role: isAdmin ? Roles.Admin : Roles.User,
            loginsCount,
            cpnSearchCount,
            latestLogin,
            latestLoginTimestamp,
            edit: { userId: id, email, isAdmin },
            delete: { userId: id, email }
          }
        }
      ),
    [users]
  )

  const getRowKey = useCallback((key: any) => key.email, [])

  const columns = useColumns()

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    if (
      pagination.current !== tableParams?.current ||
      pagination.pageSize !== tableParams?.pageSize
    ) {
      setTableParams({
        current: pagination.current || 1,
        pageSize: pagination.pageSize || defaultPageSize
      })
    }
  }

  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey={getRowKey}
      className={styles.table}
      loading={isLoading}
      onChange={handleTableChange}
      pagination={{
        ...tableParams
      }}
    />
  )
}

export default UsersTable
