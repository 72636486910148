import {
  UsergroupAddOutlined,
  LineChartOutlined,
  ContainerOutlined,
  GlobalOutlined
} from '@ant-design/icons'
import { FullRoutes, RoutesToSidebarKeys } from 'routes/constants'

export const menuConfig = [
  {
    key: RoutesToSidebarKeys[FullRoutes.UserInfoDashBoard],
    icon: <LineChartOutlined />,
    to: FullRoutes.UserInfoDashBoard,
    labelKey: 'component.common.user-info-dashboard.label'
  },
  {
    key: RoutesToSidebarKeys[FullRoutes.UserInfoMoreData],
    icon: <ContainerOutlined />,
    to: FullRoutes.UserInfoMoreData,
    labelKey: 'component.common.user-info-more-data.label'
  },
  {
    key: RoutesToSidebarKeys[FullRoutes.Home],
    icon: <GlobalOutlined />,
    to: FullRoutes.Home,
    labelKey: 'component.common.home.label'
  },
  {
    key: RoutesToSidebarKeys[FullRoutes.Admin],
    icon: <UsergroupAddOutlined />,
    to: FullRoutes.Admin,
    labelKey: 'component.common.admin.label'
  }
]
