import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { getCustomerNewsletters } from 'redux/selectors/userInfo'
import {
  Newsletter as NewsletterType,
  NewsletterActiveFlag
} from 'redux/reducers/userInfo/types'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import Flag from 'components/common/Flag'
import Content from 'components/common/Content'
import { formatDate } from 'utils/date'
import styles from './styles.module.css'

const { Title } = Typography

const Newsletter: React.FC = () => {
  const newsletters = useSelector(getCustomerNewsletters)

  const { formatMessage } = useIntl()

  const title = formatMessage({
    id: 'component.user-info.customerStats.newsletter.title'
  })

  const columns: ColumnsType<NewsletterType> = [
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.newsletter.name'
      }),
      dataIndex: 'newsletterName',
      key: 'newsletterName'
    },
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.newsletter.optIn'
      }),
      dataIndex: 'newsletterOptinDate',
      key: 'newsletterOptinDate',
      render: (date) => formatDate(date)
    },
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.newsletter.clickCount'
      }),
      dataIndex: 'clickCount',
      key: 'clickCount'
    },
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.newsletter.openCount'
      }),
      dataIndex: 'openCount',
      key: 'openCount'
    },
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.newsletter.flag'
      }),
      dataIndex: 'newsletterActiveFlag',
      key: 'newsletterActiveFlag',
      render: (value) => {
        const flagValue = value === NewsletterActiveFlag.Active ? true : false

        return <Flag isChecked={flagValue} />
      }
    }
  ]

  const getRowKey = useCallback((key: any) => key?.newsletterName, [])

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.UserInfo)
  )

  return (
    <Content
      isLoading={isLoading}
      loaderExtraClassName={styles.loaderExtraClassName}
    >
      <div className={styles.wrapper}>
        <Title level={3} className={styles.title}>
          {title}
        </Title>
        <Table
          columns={columns}
          dataSource={newsletters}
          rowKey={getRowKey}
          pagination={false}
        />
      </div>
    </Content>
  )
}

export default Newsletter
