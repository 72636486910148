export const convertTenureMonthsToYears = (
  tenure: number | undefined
): string | undefined => {
  if (!tenure) {
    return undefined
  }

  if (tenure <= 0) {
    return '-'
  }

  const years = Math.floor(tenure / 12)
  const months = tenure % 12

  if (years <= 0) {
    return `${months} months`
  }

  return `${years} years, ${months} months`
}
