import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  fetchDeviceUsage,
  fetchUserInfo,
  resetUserInfoState
} from 'redux/actions/userInfo'
import { getCpn } from 'redux/selectors/cpn'

const useFetchUserInfoData = (): void => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const cpn = useSelector(getCpn)

  useEffect(() => {
    if (cpn) {
      dispatch(fetchUserInfo())
      dispatch(fetchDeviceUsage())
    }

    return () => {
      dispatch(resetUserInfoState())
    }
  }, [dispatch, cpn, pathname])
}

export default useFetchUserInfoData
