import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { SECTION_IDS } from 'redux/reducers/sections-likes/types'
import Box from 'components/common/Box'
import VerticalBar from 'components/common/VerticalBar'
import { getDeviceUsage } from 'redux/selectors/userInfo'
import { DEVICE_VERTICAL_BAR_COLOR_PALETTE } from 'components/UserInfo/Dashboard/DeviceUsage/constants'
import { mapAndSortDeviceData } from 'components/UserInfo/Dashboard/DeviceUsage/utils'
import styles from './styles.module.css'

const DeviceUsage: React.FC = () => {
  const { formatMessage } = useIntl()
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.UserInfoDeviceUsage)
  )

  const { device, platform } = useSelector(getDeviceUsage)

  const deviceData = useMemo(() => mapAndSortDeviceData(device), [device])
  const platformData = useMemo(() => mapAndSortDeviceData(platform), [platform])

  const noDataLabel = formatMessage({
    id: 'component.user-info.customerStats.deviceUsage.noData.label'
  })

  const shouldRenderNoData = !platformData.length && !deviceData.length

  return (
    <Box
      sectionId={SECTION_IDS.DeviceUsage}
      extraWrapperClassNames={styles.extraWrapperClassNames}
      loaderExtraClassName={styles.loaderExtraClassName}
      noDataExtraClassName={styles.noDataExtraClassName}
      shouldRenderNoData={shouldRenderNoData}
      isLoading={isLoading}
      noDataText={noDataLabel}
      shouldRenderLikeSection
    >
      <VerticalBar
        data={platformData}
        extraClassName={styles.platformVerticalBar}
      />
      <VerticalBar
        data={deviceData}
        customColors={DEVICE_VERTICAL_BAR_COLOR_PALETTE}
      />
    </Box>
  )
}

export default DeviceUsage
