import { useCallback, KeyboardEventHandler } from 'react'
import { useIntl } from 'react-intl'
import { Button, Input, InputRef, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import styles from './styles.module.css'

type Props = {
  searchInput: React.RefObject<InputRef>
  selectedKeys: React.Key[]
  setSelectedKeys: (selectedKeys: React.Key[]) => void
  handleSearch: (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void
  ) => void
  clearFilters?: () => void
  confirm: (param?: FilterConfirmProps) => void
  handleReset: (clearFilters: () => void) => void
}

const SearchEmail: React.FC<Props> = ({
  searchInput,
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  clearFilters,
  confirm,
  handleReset
}) => {
  const { formatMessage } = useIntl()

  const searchEmailLabel = formatMessage({
    id: 'component.admin.search.email'
  })

  const searchLabel = formatMessage({
    id: 'component.admin.search.search'
  })

  const resetLabel = formatMessage({
    id: 'component.admin.search.reset'
  })

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => e.stopPropagation(),
    []
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = [e.target.value] || []
    setSelectedKeys(value)
  }

  const onPressEnter = useCallback(
    (): void => handleSearch(selectedKeys as string[], confirm),
    [confirm, handleSearch, selectedKeys]
  )

  const onSearch = useCallback(
    (): void => handleSearch(selectedKeys as string[], confirm),
    [confirm, handleSearch, selectedKeys]
  )

  const onReset = useCallback(
    (): void => clearFilters && handleReset(clearFilters),
    [clearFilters, handleReset]
  )

  return (
    <div onKeyDown={onKeyDown} className={styles.wrapper}>
      <Input
        ref={searchInput}
        placeholder={searchEmailLabel}
        value={selectedKeys[0]}
        onChange={onChange}
        onPressEnter={onPressEnter}
        className={styles.input}
      />
      <Space>
        <Button
          onClick={onSearch}
          icon={<SearchOutlined />}
          className={styles.button}
          size="small"
          type="primary"
        >
          {searchLabel}
        </Button>
        <Button onClick={onReset} className={styles.button} size="small">
          {resetLabel}
        </Button>
      </Space>
    </div>
  )
}

export default SearchEmail
