import { all, takeEvery, put, call } from 'redux-saga/effects'
import api from 'api'
import { handleError } from 'redux/actions/errors'
import { consumeUsersStats } from 'redux/actions/admin'
import { FETCH_USERS_STATS } from 'redux/actions/admin/constants'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchUsersStats() {
  try {
    const { data } = yield call(api.getUsersStats)

    if (data) {
      yield put(consumeUsersStats(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* usersStats() {
  yield all([takeEvery(FETCH_USERS_STATS, handleFetchUsersStats)])
}
