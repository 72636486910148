import { Params, SearchParams } from 'utils/types'

export const getValueFromQuery = (queryParam: string): string =>
  new URLSearchParams(window.location.search).get(queryParam) || ''

export const convertQueryParamsToObject = (
  searchParams: SearchParams
): Params => {
  const params: Params = {}
  searchParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
