import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import config from 'config'
import { clearErrors } from 'redux/actions/errors'
import { getErrors } from 'redux/selectors/errors'
import { statusCodeUnauthorized } from 'components/common/hooks/constants'

const useLogoutWhenUnauthorized = (): void => {
  const errors = useSelector(getErrors)
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    const notAuthorizedError = errors.find(
      (error) => error.status === statusCodeUnauthorized
    )

    if (notAuthorizedError) {
      dispatch(clearErrors())
      return logout({ logoutParams: { returnTo: config.auth.logoutUri } })
    }
  }, [dispatch, logout, errors])
}

export default useLogoutWhenUnauthorized
