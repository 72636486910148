export default {
  'component.common.noData.label': 'No Data',
  'component.common.cpn.label': 'CPN: ',
  'component.common.changeCpn.label': 'Change CPN',
  'component.common.logout.label': 'Logout',
  'component.common.home.label': 'Popular Content',
  'component.common.user-info.label': 'User Info',
  'component.common.user-info-dashboard.label': 'User Dashboard',
  'component.common.user-info-more-data.label': 'Newsletters',
  'component.common.admin.label': 'Admin Panel',
  'component.common.modal.label': 'CPN: ',
  'component.common.modal.changeCpn.label': 'Change CPN',
  'component.common.modal.title': 'Change CPN',
  'component.common.modal.save': 'Save',
  'component.common.modal.cancel': 'Cancel',
  'component.common.modal.error.message': 'The cpn field cannot be empty'
}
