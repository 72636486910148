import { all, put, takeEvery } from 'redux-saga/effects'
import parse from 'url-parse'
import { PayloadAction } from '@reduxjs/toolkit'
import { HANDLE_ERROR } from 'redux/actions/errors/constants'
import { consumeError } from 'redux/actions/errors'
import { HandleErrorPayload } from 'redux/actions/errors/types'
import { ApiRoutePaths, HTTPMethods } from 'api/types'

type Response = {
  status: number
  data?: any
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleError({
  payload: { response, config }
}: PayloadAction<HandleErrorPayload>) {
  if (response && config?.url) {
    const { status, data }: Response = response
    const { pathname } = parse(config?.url)

    yield put(
      consumeError({
        status,
        message: data,
        pathname: pathname as ApiRoutePaths,
        method: config?.method?.toUpperCase() as HTTPMethods
      })
    )
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* errors() {
  yield all([takeEvery(HANDLE_ERROR, handleError)])
}
