import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { formatDate } from 'utils/date'
import { getTimesPlusOffers } from 'redux/selectors/userInfo'
import { TimesPlusOffer } from 'redux/reducers/userInfo/types'
import { fetchTimesPlusOffers } from 'redux/actions/userInfo'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import Content from 'components/common/Content'
import { TABLE_SCROLL_CONFIG } from 'components/UserInfo/MoreData/config'
import styles from './styles.module.css'

const { Title } = Typography

const TimesPlusOffers: React.FC = () => {
  const timesPlusOffers = useSelector(getTimesPlusOffers)
  const dispatch = useDispatch()

  const { formatMessage } = useIntl()

  const title = formatMessage({
    id: 'component.user-info.customerStats.lifeTimesPlusOffers.title'
  })

  const tooltipLabel = formatMessage({
    id: 'component.user-info.customerStats.lifeTimesPlusOffers.tooltip'
  })

  useEffect(() => {
    dispatch(fetchTimesPlusOffers())
  }, [dispatch])

  const columns: ColumnsType<TimesPlusOffer> = [
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.lifeTimesPlusOffers.name'
      }),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.lifeTimesPlusOffers.type'
      }),
      dataIndex: 'type',
      key: 'type',
      width: 140
    },
    {
      title: formatMessage({
        id: 'component.user-info.customerStats.lifeTimesPlusOffers.endDate'
      }),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 120,
      render: (date) => formatDate(date)
    }
  ]

  const getRowKey = useCallback((key: any) => key?.newsletterName, [])

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.MoreDataLifeTimeOffers)
  )

  return (
    <Content
      isLoading={isLoading}
      loaderExtraClassName={styles.loaderExtraClassName}
    >
      <div className={styles.wrapper}>
        <Title level={3} className={styles.title}>
          {title}
          <Tooltip className={styles.tooltip} title={tooltipLabel}>
            <InfoCircleTwoTone />
          </Tooltip>
        </Title>
        <Table
          columns={columns}
          dataSource={timesPlusOffers}
          rowKey={getRowKey}
          pagination={false}
          scroll={TABLE_SCROLL_CONFIG}
        />
      </div>
    </Content>
  )
}

export default TimesPlusOffers
