import { useCallback, useEffect, useState } from 'react'
import { Input, Modal, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeCpnModal,
  saveCpnSearch,
  setCpn as setCpnAction
} from 'redux/actions/cpn'
import { getCpn, isModalOpen } from 'redux/selectors/cpn'
import { FullRoutes } from 'routes/constants'
import ModalBody from 'components/common/ModalBody'
import Footer from 'components/common/CpnModal/Footer'
import styles from './styles.module.css'

const { Text } = Typography

enum InputStatus {
  WARNING = 'warning',
  ERROR = 'error',
  EMPTY = ''
}

const CpnModal: React.FC = () => {
  const [cpn, setCpn] = useState<string>('')
  const [inputStatus, setInputStatus] = useState<InputStatus>(InputStatus.EMPTY)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const cpnStore = useSelector(getCpn)
  const isOpen = useSelector(isModalOpen)
  const navigate = useNavigate()

  const label = formatMessage({
    id: 'component.cpn.label'
  })

  const inputPlaceholder = formatMessage({
    id: 'component.cpn.input.placeholder'
  })

  const cpnModalTitle = formatMessage({
    id: 'component.common.modal.title'
  })

  const errorMessage = formatMessage({
    id: 'component.common.modal.error.message'
  })

  const clearState = useCallback(() => {
    setCpn(cpnStore)
    setInputStatus(InputStatus.EMPTY)
  }, [cpnStore])

  const onSave = useCallback((): void => {
    const trimmedCpn = cpn.trim()

    if (trimmedCpn && trimmedCpn !== cpnStore) {
      dispatch(setCpnAction({ cpn: trimmedCpn }))

      dispatch(
        saveCpnSearch({
          cpn: trimmedCpn
        })
      )
      dispatch(closeCpnModal())
      clearState()
    }

    if (!trimmedCpn) {
      setInputStatus(InputStatus.ERROR)
    }
  }, [clearState, dispatch, cpn, cpnStore])

  const onCancel = useCallback((): void => {
    if (!cpnStore && !cpn) {
      navigate(FullRoutes.Home)
    }

    dispatch(closeCpnModal())
    clearState()
  }, [clearState, cpn, cpnStore, dispatch, navigate])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCpn = event.target.value
      setInputStatus(newCpn ? InputStatus.EMPTY : InputStatus.ERROR)
      setCpn(newCpn)
    },
    [setCpn]
  )

  useEffect(() => {
    setCpn(cpnStore)
  }, [cpnStore])

  const isSaveDisabled =
    !cpn || cpn === cpnStore || inputStatus === InputStatus.ERROR

  return (
    <Modal
      title={cpnModalTitle}
      onCancel={onCancel}
      open={isOpen}
      footer={
        <Footer
          onSave={onSave}
          onCancel={onCancel}
          isSaveDisabled={isSaveDisabled}
        />
      }
    >
      <ModalBody>
        <Text>{label}</Text>
        <Input
          type="text"
          placeholder={inputPlaceholder}
          value={cpn}
          onChange={onChange}
          status={inputStatus}
        />

        <div className={styles.errorMessage}>
          {inputStatus === InputStatus.ERROR && (
            <Text className={styles.errorMessage}>{errorMessage}</Text>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CpnModal
