import { Typography } from 'antd'
import { useIntl } from 'react-intl'
import { ChurnBuckets } from 'redux/reducers/userInfo/types'
import { SEGMENT_TO_COLORS } from 'components/UserInfo/Dashboard/SubInfo/ChurnScore/constants'
import CrossSvg from 'components/common/Flag/Svg/crossSvg'
import styles from './styles.module.css'

const { Text } = Typography

type Props = {
  value: ChurnBuckets | ''
  description: string
}

const ChurnScore: React.FC<Props> = ({ value, description }) => {
  const { formatMessage } = useIntl()

  if (!value) {
    return <></>
  }

  const label = formatMessage({
    id: `component.user-info.churnScore.${value}`
  })

  const segmentColor =
    value === ChurnBuckets.NoChurnData ||
    value === ChurnBuckets.NoEarlyLifeData ? (
      <div className={styles.color}>
        <CrossSvg />
      </div>
    ) : (
      <div
        style={{ background: SEGMENT_TO_COLORS[value] }}
        className={styles.color}
      ></div>
    )

  return (
    <div className={styles.wrapper}>
      <div>{segmentColor}</div>
      <div className={styles.value}>
        <Text className={styles.text}>{label}</Text>
        <Text className={styles.description}>{description}</Text>
      </div>
    </div>
  )
}

export default ChurnScore
