import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { FETCH_SECTION_TOP_ARTICLES } from 'redux/actions/home/constants'
import api from 'api'
import { differenceInDays } from 'date-fns'
import {
  getSelectedLookBackDate,
  getSelectedSectionFilterValue
} from 'redux/selectors/home'
import { GetSectionTopArticlesResponseType } from 'redux/sagas/home/types'
import { consumeArticles } from 'redux/actions/home'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { handleError } from 'redux/actions/errors'
import { convertDateStringToDate } from 'utils/date'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchSectionTopArticles() {
  try {
    yield put(startLoading(Loaders.Home))

    const section: string = yield select(getSelectedSectionFilterValue)

    const selectedLookBackDate: string = yield select(getSelectedLookBackDate)

    const lookbackWindow = differenceInDays(
      new Date(),
      convertDateStringToDate(selectedLookBackDate) || new Date()
    )

    const { data }: GetSectionTopArticlesResponseType = yield call(
      api.getSectionTopArticles,
      {
        queryParams: {
          section,
          lookbackWindow
        }
      }
    )

    if (data) {
      yield put(consumeArticles(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.Home))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* home() {
  yield all([
    takeEvery(FETCH_SECTION_TOP_ARTICLES, handleFetchSectionTopArticles)
  ])
}
