import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { handleError } from 'redux/actions/errors'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { getCpn } from 'redux/selectors/cpn'
import { FETCH_USER_INFO } from 'redux/actions/userInfo/constants'
import {
  consumeCustomerDailySessions,
  consumeCustomerStats
} from 'redux/actions/userInfo'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchUserInfo() {
  try {
    yield put(startLoading(Loaders.UserInfo))
    const cpn: string = yield select(getCpn)

    const { data } = yield call(api.getUserInfo, {
      queryParams: { cpn }
    })

    if (data) {
      const { customerDailySessions, customerStats } = data

      if (customerDailySessions) {
        yield put(consumeCustomerDailySessions(customerDailySessions))
      }

      if (customerStats && Object.keys(customerStats).length > 0) {
        yield put(consumeCustomerStats(customerStats))
      }
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.UserInfo))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* userInfo() {
  yield all([takeEvery(FETCH_USER_INFO, handleFetchUserInfo)])
}
