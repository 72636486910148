import { useCallback } from 'react'
import { Typography } from 'antd'
import { WeekDays } from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/WeekdaysSidebar/constants'
import styles from './styles.module.css'

const { Text } = Typography

const WeekdaysSidebar: React.FC = () => {
  const renderWeekDays = useCallback(
    () =>
      Object.values(WeekDays).map((day) => (
        <Text key={day} className={styles.weekday}>
          {day}
        </Text>
      )),
    []
  )

  return <div className={styles.weekdays}>{renderWeekDays()}</div>
}

export default WeekdaysSidebar
