import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import { ToastContainer } from 'react-toastify'
import store from 'redux/store'
import App from 'App'
import messages from 'i18n/en_US'
import config from 'config'
import 'antd/dist/reset.css'
import 'react-toastify/dist/ReactToastify.css'
import 'css/index.css'
import { saveLoginData } from 'redux/actions/login'

const { audience, clientID, domain, redirect_uri, scope } = config.auth

// check react docs for update
const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <IntlProvider locale="en" defaultLocale="en" messages={messages}>
      <Auth0Provider
        domain={domain}
        clientId={clientID}
        authorizationParams={{
          audience,
          redirect_uri,
          scope
        }}
        useRefreshTokens
        cacheLocation="localstorage"
        onRedirectCallback={() => {
          store.dispatch(saveLoginData())
        }}
      >
        <ToastContainer />
        <App />
      </Auth0Provider>
    </IntlProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
