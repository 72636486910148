import NoData from 'components/common/Content/NoData'
import Loader from 'components/common/Content/Loader'

export type Props = {
  isLoading?: boolean
  shouldRenderNoData?: boolean
  children?: React.ReactNode
  loaderExtraClassName?: string
  noDataExtraClassName?: string
  noDataText?: string
  customLoader?: React.ReactNode
}

const Content: React.FC<Props> = ({
  children,
  isLoading,
  shouldRenderNoData,
  noDataExtraClassName,
  loaderExtraClassName,
  noDataText,
  customLoader
}) => {
  if (isLoading) {
    return customLoader ? (
      customLoader
    ) : (
      <Loader extraClassnames={loaderExtraClassName} />
    )
  }

  if (shouldRenderNoData) {
    return <NoData extraClassnames={noDataExtraClassName} text={noDataText} />
  }

  return <>{children}</>
}

export default Content
