/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from 'config'

const getValueFromStorage = (key?: string, defaultValue?: any) => {
  if (!key) {
    return defaultValue
  }

  const itemFromStorage = localStorage.getItem(key)
  const parsedValue = itemFromStorage && JSON.parse(itemFromStorage)

  return parsedValue || defaultValue
}

const getAuth0TokenFromStorage = () => {
  const { clientID, prefix } = config.auth

  const storageKeys = Object.keys(localStorage)

  const searchedKey = storageKeys.filter((key) =>
    key.includes(`${prefix}${clientID}`)
  )

  for (const key of searchedKey) {
    const storageValue = getValueFromStorage(key)
    if (storageValue?.body?.access_token) {
      return storageValue?.body?.access_token
    }
  }
}

export const getTokenFromStorage = (): string => {
  const token = getAuth0TokenFromStorage()

  return token ? `Bearer ${token}` : ''
}
