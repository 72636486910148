import {
  BrowserRouter,
  Routes as ReactRouter,
  Route,
  Navigate
} from 'react-router-dom'
import { FullRoutes, Routes } from 'routes/constants'
import Login from 'routes/Login'
import Home from 'routes/Home'
import Dashboard from 'routes/UserInfo/Dashboard'
import MoreData from 'routes/UserInfo/MoreData'
import Admin from 'routes/Admin'
import LayoutContent from 'components/common/LayoutContent'
import PrivateRoute from 'components/common/PrivateRoute'
import CpnRoute from 'components/common/CpnRoute'
import AdminRoute from 'components/common/AdminRoute'
import useNotification from 'components/common/hooks/use-notifications'
import useLogoutWhenUnauthorized from 'components/common/hooks/use-logout-when-unauthorized'
import { Analytics } from 'components/common/Analytics'

const App: React.FC = () => {
  useNotification()
  useLogoutWhenUnauthorized()
  return (
    <BrowserRouter>
      <Analytics />
      <ReactRouter>
        <Route path={Routes.Login} element={<Login />} />

        <Route path={Routes.Home} element={<LayoutContent />}>
          <Route
            index
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />

          <Route
            path={Routes.UserInfoDashBoard}
            element={
              <PrivateRoute>
                <CpnRoute>
                  <Dashboard />
                </CpnRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={Routes.UserInfoMoreData}
            element={
              <PrivateRoute>
                <CpnRoute>
                  <MoreData />
                </CpnRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={Routes.Admin}
            element={
              <PrivateRoute>
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to={FullRoutes.Login} />} />
      </ReactRouter>
    </BrowserRouter>
  )
}

export default App
