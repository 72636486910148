import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Tooltip, Typography } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { SectionTypes, ArticleType } from 'redux/reducers/home/types'
import { RootState } from 'redux/types/store'
import { getSectionArticles } from 'redux/selectors/home'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import Content from 'components/common/Content'
import Article from 'components/Home/Sections/Section/Article'
import { TOOLTIP_SECTIONS } from 'components/Home/Sections/Section/constants'
import styles from './styles.module.css'

const { Title } = Typography

export type Props = {
  section: SectionTypes
}

const Section: React.FC<Props> = ({ section }) => {
  const { formatMessage } = useIntl()
  const articles = useSelector((state: RootState) =>
    getSectionArticles(state, section)
  )
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.Home)
  )
  const shouldRenderNoData = articles.length === 0
  const title = formatMessage({
    id: `component.home.${section}.title`
  })

  const tooltip = TOOLTIP_SECTIONS[section]

  const renderArticles = useCallback(
    ({
      articleComments,
      contentHeadline,
      contentImgUrl,
      contentUrl,
      pageViews
    }: ArticleType) => (
      <Article
        key={contentUrl}
        section={section}
        articleComments={articleComments}
        contentHeadline={contentHeadline}
        contentImgUrl={contentImgUrl}
        contentUrl={contentUrl}
        pageViews={pageViews}
      />
    ),
    [section]
  )

  return (
    <div className={styles.section}>
      <Title level={4} className={styles.title}>
        {title}
        {tooltip && (
          <Tooltip className={styles.tooltip} title={tooltip}>
            <InfoCircleTwoTone />
          </Tooltip>
        )}
      </Title>
      <Content
        isLoading={isLoading}
        shouldRenderNoData={shouldRenderNoData}
        loaderExtraClassName={styles.loaderExtraClassName}
        noDataExtraClassName={styles.noDataExtraClassName}
      >
        {articles.map(renderArticles)}
      </Content>
    </div>
  )
}

export default Section
