import { createAction } from '@reduxjs/toolkit'
import {
  CONSUME_USERS,
  CONSUME_USERS_STATS,
  CREATE_USER,
  DELETE_USER,
  FETCH_USERS,
  FETCH_USERS_STATS,
  RESET_ADMIN_STATE,
  UPDATE_USER
} from 'redux/actions/admin/constants'
import {
  CreateUserPayload,
  UpdateUserPayload,
  UsersData,
  UsersStats
} from 'redux/actions/admin/types'

export const fetchUsers = createAction(FETCH_USERS)
export const fetchUsersStats = createAction(FETCH_USERS_STATS)
export const consumeUsers = createAction<UsersData>(CONSUME_USERS)
export const consumeUsersStats = createAction<UsersStats[]>(CONSUME_USERS_STATS)
export const createUser = createAction<CreateUserPayload>(CREATE_USER)
export const updateUser = createAction<UpdateUserPayload>(UPDATE_USER)
export const deleteUser = createAction<string>(DELETE_USER)
export const resetAdminState = createAction(RESET_ADMIN_STATE)
