export default {
  'component.home.title': 'Overview',
  'component.home.subtitle': 'The Times & The Sunday Times Best Content',
  'component.home.popular.title': 'Most Popular',
  'component.home.commented.title': 'Most Commented',
  'component.home.trending.title': 'Most shared',
  'component.home.engaging.title': 'Most Engaging',
  'component.home.section.label': 'Section: ',
  'component.home.comments.label': 'Comments: ',
  'component.home.views.label': 'Views: '
}
