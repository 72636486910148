export enum HTTPHeadersType {
  ContentType = 'Content-Type'
}

export enum HttpContentTypeHeaderValues {
  ApplicationJSON = 'application/json'
}

type HTTPContentTypeHeader = Record<
  HTTPHeadersType.ContentType,
  HttpContentTypeHeaderValues
>

export type HTTPHeaders = HTTPContentTypeHeader

export type FetchParams = Record<string, any>
export type FetchBody = Record<string, any>

export enum ApiRouteNames {
  GetSurvey = 'getSurvey',
  GetSectionTopArticles = 'getSectionTopArticles',
  GetSignOnArticle = 'getSignOnArticle',
  GetCustomerStats = 'getCustomerStats',
  GetCustomerDailySessions = 'getCustomerDailySessions',
  GetUserInfo = 'getUserInfo',
  GetTimesPlusOffers = 'getTimesPlusOffers',
  GetTimesPlusRedemption = 'getTimesPlusRedemption',
  GetDeviceUsage = 'getDeviceUsage',
  SaveCpnSearch = 'saveCpnSearch',
  SaveLoginData = 'saveLoginData',
  SaveSectionLike = 'saveSectionLike',
  GetUsers = 'getUsers',
  GetUsersStats = 'getUsersStats',
  CreateUser = 'createUser',
  UpdateUser = 'updateUser',
  DeleteUser = 'deleteUser'
}

export enum ApiRoutePaths {
  Survey = '/data/survey',
  SectionTopArticles = '/data/top-articles',
  SignOnArticle = '/data/sign-on-article',
  CustomerStats = '/data/customer-stats',
  CustomerDailySessions = '/data/customer-daily-sessions',
  UserInfo = '/data/user-info',
  TimesPlusOffers = '/data/times-plus-offers',
  DeviceUsage = '/data/device-usage',
  TimesPlusRedemption = '/data/times-plus-redemption',
  SaveCpnSearch = '/data/save-cpn-search',
  SaveLoginData = '/data/save-login-data',
  SaveSectionLike = '/data/save-section-like',
  UsersStats = '/data/users-stats',
  Users = '/admin/users',
  CreateUser = '/admin/user',
  UpdateUser = '/admin/user/:userId',
  DeleteUser = '/admin/user/:userId'
}

export interface FetchArgsType {
  body?: FetchBody
  urlParams?: FetchParams
  queryParams?: FetchParams
}

export interface ReplaceUrlArgsType {
  url: string
  urlParams?: FetchParams
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export interface ApiRoute {
  url: ApiRoutePaths
  method: HTTPMethods
  headers: HTTPHeaders
}

export type ApiRoutesType = Record<string, ApiRoute>

export type WrappedApiCallback = (args?: FetchArgsType) => Promise<any>
