import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Image, Typography } from 'antd'
import { getCustomerStats } from 'redux/selectors/userInfo'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { getIsLoading } from 'redux/selectors/loaders'
import { navigateExternal } from 'utils/navigate-external'
import Stat from 'components/common/Stat'
import Box from 'components/common/Box'
import { SECTION_IDS } from 'redux/reducers/sections-likes/types'
import styles from './styles.module.css'

const { Title, Text } = Typography

const SignOnArticle: React.FC = () => {
  const { formatMessage } = useIntl()
  const signOnArticle = useSelector(getCustomerStats)

  const noDataLabel = formatMessage({
    id: 'component.user-info.signUpArticle.noData'
  })

  const deviceTypeLabel = formatMessage({
    id: `component.user-info.signOnDeviceType`
  })

  const signOnReferrer = formatMessage({
    id: `component.user-info.signOnReferrer`
  })

  const signOnImageAlt = formatMessage({
    id: `component.user-info.signUpArticle.alt`
  })

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.UserInfo)
  )

  const signOnArticleKeys = Object.keys(signOnArticle || {})
  const signOnArticleUrl = signOnArticle?.signOnArticle

  const shouldRenderNoData = useMemo(() => {
    return !signOnArticle || !signOnArticleKeys.length || !signOnArticleUrl
  }, [signOnArticle, signOnArticleKeys.length, signOnArticleUrl])

  const redirectToArticle = useCallback(() => {
    if (signOnArticle?.signOnArticle) {
      navigateExternal(signOnArticle.signOnArticle)
    }
  }, [signOnArticle])

  const imageSrc = signOnArticle?.signUpArticleImageUrl
    ? signOnArticle.signUpArticleImageUrl
    : '/img/placeholder-image.jpg'

  return (
    <Box
      sectionId={SECTION_IDS.SingOnArticle}
      shouldRenderNoData={shouldRenderNoData}
      noDataExtraClassName={styles.noDataExtraClassName}
      noDataText={noDataLabel}
      isLoading={isLoading}
      shouldRenderLikeSection
      loaderExtraClassName={styles.loaderExtraClassName}
      extraWrapperClassNames={styles.extraWrapperClassNames}
      extraBodyClassNames={styles.extraBodyClassNames}
    >
      <div className={styles.wrapper} onClick={redirectToArticle}>
        <Image
          src={imageSrc}
          alt={signOnImageAlt}
          preview={false}
          className={styles.image}
        />

        <div className={styles.articleBody}>
          {signOnArticle?.signUpArticleHeadline && (
            <>
              <Title className={styles.title}>
                {signOnArticle.signUpArticleHeadline}
              </Title>
              <Text className={styles.authors}>
                {signOnArticle?.signUpArticleAuthors}
              </Text>
            </>
          )}

          <div className={styles.stat}>
            <Stat
              label={deviceTypeLabel}
              value={signOnArticle.signOnDeviceType}
            />
            <Stat label={signOnReferrer} value={signOnArticle.signOnReferrer} />
          </div>
        </div>
      </div>
    </Box>
  )
}

export default SignOnArticle
