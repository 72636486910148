import { Typography } from 'antd'
import classNames from 'classnames'
import styles from './styles.module.css'

const { Text } = Typography

type Props = {
  label: string
  value?: string | number
  extraClassName?: string
  extraValueClassName?: string
  extraLabelClassName?: string
  shouldRenderEmptyValue?: boolean
}

const Stat: React.FC<Props> = ({
  label,
  value,
  extraClassName,
  extraValueClassName,
  extraLabelClassName,
  shouldRenderEmptyValue
}) => {
  const isValueUndefined = value === undefined || value === null

  if (shouldRenderEmptyValue && isValueUndefined) {
    return <></>
  }

  return (
    <div className={classNames(styles.wrapper, extraClassName)}>
      <Text className={classNames(styles.label, extraLabelClassName)}>
        {label}
      </Text>
      <Text className={classNames(styles.value, extraValueClassName)}>
        {isValueUndefined ? '-' : value}
      </Text>
    </div>
  )
}

export default Stat
