export enum ReducersType {
  Cpn = 'cpn',
  Login = 'login',
  Home = 'home',
  UserInfo = 'userInfo',
  Admin = 'admin',
  Loaders = 'loaders',
  Errors = 'errors',
  Notifications = 'notifications',
  Likes = 'likes'
}
