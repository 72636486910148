import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getCpn } from 'redux/selectors/cpn'
import { convertQueryParamsToObject } from 'utils/query-params'

const useSetCpnInQuery = (): void => {
  const dispatch = useDispatch()
  const cpn = useSelector(getCpn)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const params = convertQueryParamsToObject(searchParams)

    if (cpn) {
      setSearchParams({ ...params, cpn })
    }
  }, [cpn, dispatch, searchParams, setSearchParams])
}

export default useSetCpnInQuery
