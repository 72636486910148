import { format } from 'date-fns'
import { FormatDate } from 'utils/types'
import { UK_DATE_FORMAT } from 'utils/constants'

export const formatDate: FormatDate = (date, dateFormat): string =>
  format(new Date(date), dateFormat ? dateFormat : UK_DATE_FORMAT)

export const convertDateStringToDate = (
  dateString: string,
  splitDateChar?: string
): Date | null => {
  const [day, month, year] = dateString
    .split(splitDateChar ? splitDateChar : '-')
    .map(Number)

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return null
  }

  return new Date(year, month - 1, day)
}
