export const config = {
  dev: {
    api: {
      url: 'http://localhost:3001'
    },
    timesArticleBaseUrl: 'https://www.thetimes.co.uk/article',
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuktooling-staging.eu.auth0.com',
      clientID: 'UccqxtsKIotHt3mhDayPaobCoIZxNYWQ',
      audience: 'https://data-api.staging-news.co.uk/',
      redirect_uri: 'http://localhost:3000?authCallBack=true',
      logoutUri: 'http://localhost:3000/login',
      responseType: 'token id_token'
    }
  },
  staging: {
    api: {
      url: 'https://call-centre-recs.staging-news.co.uk/api'
    },
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuktooling-staging.eu.auth0.com',
      clientID: 'UccqxtsKIotHt3mhDayPaobCoIZxNYWQ',
      audience: 'https://data-api.staging-news.co.uk/',
      redirect_uri:
        'https://call-centre-recs.staging-news.co.uk?authCallBack=true',
      logoutUri: 'https://call-centre-recs.staging-news.co.uk/login',
      responseType: 'token id_token'
    }
  },
  prod: {
    api: {
      url: 'https://call-centre-recs.news.co.uk/api'
    },
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuktooling-prod.eu.auth0.com',
      clientID: 'SmlKQ9cbs67tFl8xlPNRoLpILkU43sPa',
      audience: 'https://data-api.news.co.uk/',
      redirect_uri: 'https://call-centre-recs.news.co.uk?authCallBack=true',
      logoutUri: 'https://call-centre-recs.news.co.uk/login',
      responseType: 'token id_token'
    }
  }
}
