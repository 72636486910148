import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import Loader from 'components/common/Content/Loader'
import config from 'config'
import { FullRoutes } from 'routes/constants'
import styles from './styles.module.css'

const { audience, scope } = config.auth

const Login: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: { audience, scope }
      })
    }
  }, [loginWithRedirect, isAuthenticated, isLoading])

  useEffect(() => {
    if (isAuthenticated) {
      navigate(FullRoutes.UserInfoDashBoard)
    }
  }, [isAuthenticated, navigate])

  return <Loader extraClassnames={styles.loader} />
}

export default Login
