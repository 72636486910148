import {
  allowedDomains,
  emailRegex
} from 'components/Admin/CreteUser/constants'

export const validateEmail = (email: string): string => {
  if (!emailRegex.test(email)) {
    return 'component.admin.create.modal.email.error.invalid'
  }

  const domainName = email.split('@')[1]
  const doesEmailContainsDomainName = allowedDomains.includes(domainName)

  if (!doesEmailContainsDomainName) {
    return 'component.admin.create.modal.email.error.domain'
  }

  return ''
}
