import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { CpnInitialState } from 'redux/reducers/cpn/constants'
import { SetCpnPayload } from 'redux/actions/cpn/types'

export const cpnSlice = createSlice({
  name: ReducersType.Cpn,
  initialState: CpnInitialState,
  reducers: {
    setCpn: (state, { payload: { cpn } }: PayloadAction<SetCpnPayload>) => ({
      ...state,
      cpn
    }),
    openCpnModal: (state) => ({
      ...state,
      isModalOpen: true
    }),
    closeCpnModal: (state) => ({
      ...state,
      isModalOpen: false
    })
  }
})
