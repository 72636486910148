import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import api from 'api'
import { handleError } from 'redux/actions/errors'
import { LIKE_SECTION } from 'redux/actions/sections-likes/constants'
import { getCpn } from 'redux/selectors/cpn'
import { saveLikeSection } from 'redux/actions/sections-likes'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleLikeSection({ payload: { page, section } }: any) {
  try {
    const cpn: string = yield select(getCpn)

    yield put(saveLikeSection({ section }))
    yield call(api.saveSectionLike, {
      body: {
        section,
        page,
        cpn
      }
    })
  } catch (err: any) {
    yield put(handleError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* sectionsLikes() {
  yield all([takeEvery(LIKE_SECTION, handleLikeSection)])
}
