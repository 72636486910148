import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Modal, Typography } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { deleteUser } from 'redux/actions/admin'
import ModalFooter from 'components/Admin/ModalFooter/index'
import ModalBody from 'components/common/ModalBody'

const { Text } = Typography

type Props = {
  userId: string
  email: string
}

const DeleteUser: React.FC<Props> = ({ userId, email }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const deleteModalTitle = formatMessage({
    id: 'component.admin.delete.modal.title'
  })

  const modalCancelButton = formatMessage({
    id: 'component.admin.delete.modal.cancel'
  })

  const modalDeleteButton = formatMessage({
    id: 'component.admin.delete.modal.delete'
  })

  const modalText = formatMessage({
    id: 'component.admin.delete.modal.text'
  })

  const onClick = useCallback(() => setIsModalOpen(true), [])

  const onCancel = useCallback(() => setIsModalOpen(false), [])

  const onDelete = useCallback(() => {
    dispatch(deleteUser(userId))
    onCancel()
  }, [dispatch, onCancel, userId])

  return (
    <>
      <DeleteFilled onClick={onClick} />
      <Modal
        title={deleteModalTitle}
        open={isModalOpen}
        onCancel={onCancel}
        footer={
          <ModalFooter
            cancelLabel={modalCancelButton}
            okLabel={modalDeleteButton}
            onOkClick={onDelete}
            onCancelClick={onCancel}
            isOkDanger
          />
        }
      >
        <ModalBody>
          {modalText} <Text strong>{email}</Text>?
        </ModalBody>
      </Modal>
    </>
  )
}

export default DeleteUser
