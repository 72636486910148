import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Popover, Typography } from 'antd'
import classNames from 'classnames'
import { formatDate } from 'utils/date'
import { PrintVoucher } from 'redux/reducers/userInfo/types'
import { getDayColor } from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/DaysBoxes/DayBox/utils'
import styles from './styles.module.css'

interface Props {
  printVoucher?: PrintVoucher
  date: Date
  column: number
  row: number
}

const { Text } = Typography

const DayBox: React.FC<Props> = ({ printVoucher, date, column, row }) => {
  const dateColor = useMemo(() => getDayColor(printVoucher), [printVoucher])

  const { formatMessage } = useIntl()

  const issueDateLabel = formatMessage({
    id: 'component.user-info.printVouchers.issueDate'
  })

  const boxNumberRetailerLabel = formatMessage({
    id: 'component.user-info.printVouchers.boxNumberRetailer'
  })

  const dateLabel = formatMessage({
    id: 'component.user-info.printVouchers.date'
  })

  return (
    <Popover
      key={formatDate(date)}
      content={
        printVoucher ? (
          <div className={styles.printVoucherPopover}>
            <Text>
              {issueDateLabel}
              {formatDate(date)}
            </Text>
            <Text>
              {boxNumberRetailerLabel}
              {printVoucher.boxNumberRetailer}
            </Text>
          </div>
        ) : (
          <Text>
            {dateLabel}
            {formatDate(date)}
          </Text>
        )
      }
    >
      <div
        className={classNames(styles.day, styles[dateColor])}
        style={{
          gridColumnStart: column,
          gridRowStart: row
        }}
      />
    </Popover>
  )
}

export default DayBox
