import { ReducersType } from 'redux/reducers/types'

export const FETCH_USERS = `${ReducersType.Admin}/fetchUsers`
export const FETCH_USERS_STATS = `${ReducersType.Admin}/fetchUsersStats`
export const CONSUME_USERS = `${ReducersType.Admin}/consumeUsers`
export const CONSUME_USERS_STATS = `${ReducersType.Admin}/consumeUsersStats`
export const CREATE_USER = `${ReducersType.Admin}/createUser`
export const UPDATE_USER = `${ReducersType.Admin}/updateUser`
export const DELETE_USER = `${ReducersType.Admin}/deleteUser`
export const RESET_ADMIN_STATE = `${ReducersType.Admin}/resetAdminState`
