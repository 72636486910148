import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import config from 'config'
import { openCpnModal } from 'redux/actions/cpn'
import { getCpn } from 'redux/selectors/cpn'
import styles from './styles.module.css'

const Menu: React.FC = () => {
  const { logout, user } = useAuth0()
  const dispatch = useDispatch()
  const cpn = useSelector(getCpn)
  const { formatMessage } = useIntl()

  const email = user?.userProfile?.email

  const cpnLabel = formatMessage({
    id: 'component.common.cpn.label'
  })

  const logoutLabel = formatMessage({
    id: 'component.common.changeCpn.label'
  })

  const changeCpnLabel = formatMessage({
    id: 'component.common.logout.label'
  })

  const onChangeCpn = useCallback(() => {
    dispatch(openCpnModal())
  }, [dispatch])

  const onLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: config.auth.logoutUri } })
  }, [logout])

  return (
    <div className={styles.menu}>
      {cpn && (
        <div className={styles.activeCpn}>
          {cpnLabel}
          <div className={styles.cpn}>{cpn}</div>
        </div>
      )}
      <Button
        type="primary"
        ghost
        onClick={onChangeCpn}
        className={styles.button}
      >
        {logoutLabel}
      </Button>
      {email && <div className={styles.email}>{email}</div>}
      <Button
        type="primary"
        danger
        onClick={onLogout}
        className={styles.button}
      >
        {changeCpnLabel}
      </Button>
    </div>
  )
}

export default Menu
