import { Navigate } from 'react-router-dom'
import { FullRoutes } from 'routes/constants'
import useIsAdmin from 'components/common/hooks/use-is-admin'

export type Props = {
  children?: React.ReactNode
}

const AdminRoute: React.FC<Props> = ({ children }) => {
  const isAdmin = useIsAdmin()

  return isAdmin ? <>{children}</> : <Navigate to={FullRoutes.Home} />
}

export default AdminRoute
