import {
  NotificationsStateType,
  NotificationsType
} from 'redux/reducers/notifications/types'

export const defaultErrorNotificationsMessageId =
  'component.notification.error.label'

export const NotificationsInitialState: NotificationsStateType = {
  notification: {
    messageId: defaultErrorNotificationsMessageId,
    isOpen: false,
    type: NotificationsType.ERROR
  }
}
