import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { getCustomerStats } from 'redux/selectors/userInfo'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { SECTION_IDS } from 'redux/reducers/sections-likes/types'
import Box from 'components/common/Box'
import PriceIncrease from 'components/UserInfo/Dashboard/SubInfo/PriceIncrease'
import SubStats from 'components/UserInfo/Dashboard/SubInfo/SubStats'
import styles from './styles.module.css'

const SubInfo: React.FC = () => {
  const {
    mpCode,
    tenureMonths,
    churnBuckets,
    churnSegmentExplanation,
    appDownloadedFlag,
    priceMigrationFlag
  } = useSelector(getCustomerStats)

  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.UserInfo)
  )

  const shouldRenderNoData =
    !mpCode &&
    !tenureMonths &&
    !churnBuckets &&
    !churnSegmentExplanation &&
    !appDownloadedFlag &&
    !priceMigrationFlag

  return (
    <Box
      sectionId={SECTION_IDS.SubInfo}
      isLoading={isLoading}
      shouldRenderNoData={shouldRenderNoData}
      extraWrapperClassNames={styles.extraWrapperClassNames}
      extraBodyClassNames={styles.extraBodyClassNames}
      loaderExtraClassName={styles.loaderExtraClassName}
      noDataExtraClassName={styles.noDataExtraClassName}
      shouldRenderLikeSection
    >
      {priceMigrationFlag ? <PriceIncrease /> : <SubStats />}
    </Box>
  )
}

export default SubInfo
