import { logoPath } from 'components/common/LayoutContent/Sidebar/Logo/constants'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { FullRoutes } from 'routes/constants'
import styles from './styles.module.css'

const Logo: React.FC = () => {
  const navigate = useNavigate()
  const redirectToHomePage = useCallback(() => {
    navigate(FullRoutes.Home)
  }, [navigate])

  return (
    <div className={styles.wrapper} onClick={redirectToHomePage}>
      <img className={styles.largeLogo} src={logoPath} alt="NewsUK Logo" />
    </div>
  )
}

export default Logo
