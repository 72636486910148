import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openCpnModal } from 'redux/actions/cpn'
import { getCpn } from 'redux/selectors/cpn'

export type Props = {
  children?: React.ReactNode
}

const CpnRoute: React.FC<Props> = ({ children }) => {
  const cpn = useSelector(getCpn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!cpn) {
      dispatch(openCpnModal())
    }
  }, [cpn, dispatch])

  return cpn ? <>{children}</> : <></>
}

export default CpnRoute
