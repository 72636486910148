import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'redux/sagas'
import {
  cpnSlice,
  errorsSlice,
  loadersSlice,
  homeSlice,
  userInfoSlice,
  adminSlice,
  notificationsSlice,
  sectionLikesSlice
} from 'redux/reducers'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    cpn: cpnSlice.reducer,
    loaders: loadersSlice.reducer,
    errors: errorsSlice.reducer,
    home: homeSlice.reducer,
    userInfo: userInfoSlice.reducer,
    admin: adminSlice.reducer,
    notifications: notificationsSlice.reducer,
    sectionLikes: sectionLikesSlice.reducer
  },
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export default store
