import { createAction } from '@reduxjs/toolkit'
import {
  CLOSE_CPN_MODAL,
  OPEN_CPN_MODAL,
  SET_CPN,
  SAVE_CPN_SEARCH
} from 'redux/actions/cpn/constants'
import { SaveCpnSearchPayload, SetCpnPayload } from 'redux/actions/cpn/types'

export const setCpn = createAction<SetCpnPayload>(SET_CPN)
export const saveCpnSearch = createAction<SaveCpnSearchPayload>(SAVE_CPN_SEARCH)
export const openCpnModal = createAction(OPEN_CPN_MODAL)
export const closeCpnModal = createAction(CLOSE_CPN_MODAL)
