import { createAction } from '@reduxjs/toolkit'
import {
  FETCH_USER_INFO,
  RESET_STATE,
  CONSUME_CUSTOMER_STATS,
  CONSUME_CUSTOMER_DAILY_SESSIONS,
  FETCH_TIMES_PLUS_OFFERS,
  CONSUME_TIMES_PLUS_OFFERS,
  FETCH_TIMES_PLUS_REDEMPTION,
  CONSUME_TIMES_PLUS_REDEMPTION,
  FETCH_DEVICE_USAGE,
  CONSUME_DEVICE_USAGE
} from 'redux/actions/userInfo/constants'
import {
  CustomerDailySession,
  CustomerStats,
  DeviceUsageByType,
  TimesPlusOffer,
  TimesPlusRedemption
} from 'redux/reducers/userInfo/types'

export const fetchUserInfo = createAction(FETCH_USER_INFO)
export const fetchTimesPlusOffers = createAction(FETCH_TIMES_PLUS_OFFERS)
export const fetchTimesPlusRedemption = createAction(
  FETCH_TIMES_PLUS_REDEMPTION
)
export const fetchDeviceUsage = createAction(FETCH_DEVICE_USAGE)
export const consumeCustomerDailySessions = createAction<
  CustomerDailySession[]
>(CONSUME_CUSTOMER_DAILY_SESSIONS)
export const consumeCustomerStats = createAction<CustomerStats>(
  CONSUME_CUSTOMER_STATS
)
export const consumeTimesPlusOffers = createAction<TimesPlusOffer[]>(
  CONSUME_TIMES_PLUS_OFFERS
)
export const consumeTimesPlusRedemption = createAction<TimesPlusRedemption[]>(
  CONSUME_TIMES_PLUS_REDEMPTION
)
export const consumeDeviceUsage =
  createAction<DeviceUsageByType>(CONSUME_DEVICE_USAGE)
export const resetUserInfoState = createAction(RESET_STATE)
