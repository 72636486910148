export type ArticleType = {
  articleComments: number
  articleShare: number
  contentHeadline: string
  contentImgUrl: string
  contentSection: string
  contentUrl: string
  dwellTimeIndex: number
  firstPublishedDatetime: string
  pageViews: number
}

export type Articles = {
  popular: ArticleType[]
  commented: ArticleType[]
  trending: ArticleType[]
  engaging: ArticleType[]
}

export type HomeStateType = {
  articles: Articles
  sectionFilterValue: string
  lookBackDate: string
}

export enum SectionTypes {
  Popular = 'popular',
  Commented = 'commented',
  Trending = 'trending',
  Engaging = 'engaging'
}
