import { subDays } from 'date-fns'
import { RENDER_PAST_DAYS_COUNT } from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/DaysBoxes/constants'

export const generatePastDays = (): Date[] => {
  const days: Date[] = []
  for (let i = RENDER_PAST_DAYS_COUNT; i >= 0; i--) {
    const date = subDays(new Date(), i)
    days.push(date)
  }
  return days
}
