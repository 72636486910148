import { all, takeEvery, put, call } from 'redux-saga/effects'
import api from 'api'
import { handleError } from 'redux/actions/errors'
import { fetchUsers } from 'redux/actions/admin'
import { UPDATE_USER } from 'redux/actions/admin/constants'
import { setNotification } from 'redux/actions/notifications'
import { NotificationsType } from 'redux/reducers/notifications/types'
import { defaultErrorNotificationsMessageId } from 'redux/reducers/notifications/constants'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleUpdateUser({ payload: { userId, isAdmin } }: any) {
  try {
    yield call(api.updateUser, {
      urlParams: { userId },
      body: { isAdmin }
    })

    yield put(fetchUsers())
  } catch (err: any) {
    yield put(handleError(err))
    yield put(
      setNotification({
        messageId: defaultErrorNotificationsMessageId,
        type: NotificationsType.ERROR,
        isOpen: true
      })
    )
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* updateUser() {
  yield all([takeEvery(UPDATE_USER, handleUpdateUser)])
}
