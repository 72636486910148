import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HomeInitialState } from 'redux/reducers/home/constants'
import { ReducersType } from 'redux/reducers/types'
import {
  SelectLookBackFilterDatePayload,
  SelectSectionFilterValuePayload,
  ConsumeArticlesActionPayload
} from 'redux/actions/home/types'

export const homeSlice = createSlice({
  name: ReducersType.Home,
  initialState: HomeInitialState,
  reducers: {
    consumeArticles: (
      state,
      { payload: { articles } }: PayloadAction<ConsumeArticlesActionPayload>
    ) => {
      state.articles = articles
    },
    selectSectionFilterValue: (
      state,
      { payload }: PayloadAction<SelectSectionFilterValuePayload>
    ) => {
      state.sectionFilterValue = payload
    },
    selectLookBackDate: (
      state,
      { payload }: PayloadAction<SelectLookBackFilterDatePayload>
    ) => {
      state.lookBackDate = payload
    },
    resetTopArticles: (state) => {
      state.articles = HomeInitialState.articles
    },
    resetLookBackDate: (state) => {
      state.lookBackDate = HomeInitialState.lookBackDate
    },
    resetSectionFilterValue: (state) => {
      state.sectionFilterValue = HomeInitialState.sectionFilterValue
    }
  }
})
