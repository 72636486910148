import { useCallback } from 'react'
import DayBox from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/DaysBoxes/DayBox/index'
import { generatePastDays } from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/DaysBoxes/utils'
import { formatDate } from 'utils/date'
import { useSelector } from 'react-redux'
import { getPrintVouchers } from 'redux/selectors/userInfo'
import styles from './styles.module.css'

const DaysBoxes: React.FC = () => {
  const printVouchers = useSelector(getPrintVouchers)
  const pastDays = generatePastDays()

  const getDataForDay = useCallback(
    (date: Date) => {
      const formattedDate = formatDate(date)
      return printVouchers?.find(
        (item) => formatDate(item.issueDate) === formattedDate
      )
    },
    [printVouchers]
  )

  // TODO: Refactor the renderDays function to improve readability and maintainability.
  const renderDays = useCallback(() => {
    const firstDay = pastDays[0].getDay()
    const offset = !firstDay ? 6 : firstDay - 1

    const dayElements = []
    for (let i = 0; i < offset; i++) {
      dayElements.push(<div key={i} className={styles.day} />)
    }

    pastDays.forEach((date, index) => {
      const column = Math.floor((index + offset) / 7) + 1
      const row = ((index + offset) % 7) + 1
      const printVoucher = getDataForDay(date)
      dayElements.push(
        <DayBox
          printVoucher={printVoucher}
          date={date}
          column={column}
          row={row}
        />
      )
    })

    return dayElements
  }, [getDataForDay, pastDays])

  return <div className={styles.days}>{renderDays()}</div>
}

export default DaysBoxes
