import { ChurnBuckets } from 'redux/reducers/userInfo/types'

export const SEGMENT_TO_COLORS = {
  [ChurnBuckets.NoEarlyLifeData]: '#00c700',
  [ChurnBuckets.NoChurnData]: '#00c700',
  [ChurnBuckets.BaseLow]: '#00c700',
  [ChurnBuckets.Medium]: '#b9ff4e',
  [ChurnBuckets.High]: '#FFFF00',
  [ChurnBuckets.Higher]: '#FFD700',
  [ChurnBuckets.Highest]: '#FFA500',
  [ChurnBuckets.Top]: '#FF0000'
}
