import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import api from 'api'
import { handleError } from 'redux/actions/errors'
import { FETCH_TIMES_PLUS_REDEMPTION } from 'redux/actions/userInfo/constants'
import { consumeTimesPlusRedemption } from 'redux/actions/userInfo'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { getCpn } from 'redux/selectors/cpn'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchTimesPlusRedemption() {
  try {
    yield put(startLoading(Loaders.MoreDataTimesPlusRedemption))
    const cpn: string = yield select(getCpn)

    const { data } = yield call(api.getTimesPlusRedemption, {
      queryParams: { cpn }
    })

    if (data?.length) {
      yield put(consumeTimesPlusRedemption(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.MoreDataTimesPlusRedemption))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* timesPlusRedemption() {
  yield all([
    takeEvery(FETCH_TIMES_PLUS_REDEMPTION, handleFetchTimesPlusRedemption)
  ])
}
