import { DeviceUsage, DeviceUsageByType } from 'redux/reducers/userInfo/types'

export const mapDeviceUsageData = (data: DeviceUsage[]): DeviceUsageByType =>
  data.reduce((acc: DeviceUsageByType, item: DeviceUsage) => {
    if (!acc[item.type]) {
      acc[item.type] = []
    }
    acc[item.type].push(item)
    return acc
  }, {} as DeviceUsageByType)
