import { ReducersType } from 'redux/reducers/types'

export const FETCH_USER_INFO = `${ReducersType.UserInfo}/fetchUserInfo`
export const FETCH_TIMES_PLUS_OFFERS = `${ReducersType.UserInfo}/fetchTimesPlusOffers`
export const FETCH_TIMES_PLUS_REDEMPTION = `${ReducersType.UserInfo}/fetchTimesPlusRedemption`
export const FETCH_DEVICE_USAGE = `${ReducersType.UserInfo}/fetchDeviceUsage`
export const CONSUME_CUSTOMER_DAILY_SESSIONS = `${ReducersType.UserInfo}/consumeCustomerDailySessions`
export const CONSUME_CUSTOMER_STATS = `${ReducersType.UserInfo}/consumeCustomerStats`
export const CONSUME_TIMES_PLUS_OFFERS = `${ReducersType.UserInfo}/consumeTimesPlusOffers`
export const CONSUME_TIMES_PLUS_REDEMPTION = `${ReducersType.UserInfo}/consumeTimesPlusRedemption`
export const CONSUME_DEVICE_USAGE = `${ReducersType.UserInfo}/consumeDeviceUsage`
export const RESET_STATE = `${ReducersType.UserInfo}/resetState`
