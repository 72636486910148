import { all, takeEvery, put, call } from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { handleError } from 'redux/actions/errors'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { consumeUsers } from 'redux/actions/admin'
import { FETCH_USERS } from 'redux/actions/admin/constants'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleFetchUsers() {
  try {
    yield put(startLoading(Loaders.Admin))

    const { data } = yield call(api.getUsers)

    if (data) {
      yield put(consumeUsers(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.Admin))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* users() {
  yield all([takeEvery(FETCH_USERS, handleFetchUsers)])
}
