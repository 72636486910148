import { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import Highlighter from 'react-highlight-words'
import { InputRef } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { SearchOutlined } from '@ant-design/icons'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import DeleteUser from 'components/Admin/UserTable/DeleteUser'
import EditUser from 'components/Admin/UserTable/EditUser'
import { AdminBadge, UserBadge } from 'components/Admin/UserTable/Badge'
import {
  sortCpnSearchCount,
  sortEmails,
  sortLatestLogin,
  sortLoginsCount,
  sortRoles
} from 'components/Admin/UserTable/utils/sorters'
import { Roles } from 'components/Admin/constants'
import SearchEmail from 'components/Admin/UserTable/SearchEmail'
import {
  FilterDropdownEmail,
  RenderDelete,
  RenderEdit,
  TableUsers
} from 'components/Admin/UserTable/types'
import styles from './styles.module.css'

export default (): ColumnsType<TableUsers> => {
  const { formatMessage } = useIntl()
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef<InputRef>(null)

  const handleSearch = useCallback(
    (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void
    ): void => {
      confirm()
      setSearchText(selectedKeys[0])
    },
    []
  )

  const handleReset = useCallback((clearFilters: () => void): void => {
    clearFilters()
    setSearchText('')
  }, [])

  const renderDelete: RenderDelete = useCallback(
    ({ userId, email }) => <DeleteUser userId={userId} email={email} />,
    []
  )

  const renderEdit: RenderEdit = useCallback(
    ({ userId, email, isAdmin }) => (
      <EditUser userId={userId} email={email} isAdmin={isAdmin} />
    ),
    []
  )

  const renderRole = useCallback(
    (role: Roles) => (role === Roles.Admin ? <AdminBadge /> : <UserBadge />),
    []
  )

  const renderEmail = useCallback(
    (text: string) => (
      <Highlighter
        className={styles.highlighter}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
    [searchText]
  )

  const filterDropdownEmail: FilterDropdownEmail = useCallback(
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <SearchEmail
        clearFilters={clearFilters}
        confirm={confirm}
        handleReset={handleReset}
        handleSearch={handleSearch}
        searchInput={searchInput}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    [handleReset, handleSearch]
  )

  const filterIconEmail = useCallback(
    (filtered: boolean) => (
      <SearchOutlined
        className={filtered ? styles.searchOutlinedIcon : undefined}
      />
    ),
    []
  )

  const onFilterEmail = useCallback(
    (value: string | number | boolean, record: TableUsers) => {
      if (record.email) {
        return record.email
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
      }
      return false
    },
    []
  )

  const onFilterDropdownOpenChangeEmail = useCallback((visible: boolean) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100)
    }
  }, [])

  const onFilterRoles = useCallback(
    (value: string | number | boolean, record: TableUsers) =>
      value === record.role,
    []
  )

  return [
    {
      title: formatMessage({
        id: 'component.admin.table.role'
      }),
      dataIndex: 'role',
      key: 'role',
      width: 110,
      sorter: sortRoles,
      render: renderRole,
      filters: [
        { text: 'User', value: Roles.User },
        { text: 'Admin', value: Roles.Admin }
      ],
      onFilter: onFilterRoles
    },
    {
      title: formatMessage({
        id: 'component.admin.table.email'
      }),
      dataIndex: 'email',
      key: 'email',
      sorter: sortEmails,
      filterDropdown: filterDropdownEmail,
      filterIcon: filterIconEmail,
      onFilter: onFilterEmail,
      onFilterDropdownOpenChange: onFilterDropdownOpenChangeEmail,
      render: renderEmail
    },
    {
      title: formatMessage({
        id: 'component.admin.table.loginsCount'
      }),
      dataIndex: 'loginsCount',
      key: 'loginsCount',
      sorter: sortLoginsCount
    },
    {
      title: formatMessage({
        id: 'component.admin.table.cpnSearchCount'
      }),
      dataIndex: 'cpnSearchCount',
      key: 'cpnSearchCount',
      sorter: sortCpnSearchCount
    },
    {
      title: formatMessage({
        id: 'component.admin.table.latestLogin'
      }),
      dataIndex: 'latestLogin',
      key: 'latestLogin',
      sorter: {
        compare: sortLatestLogin,
        multiple: 2
      }
    },
    {
      title: formatMessage({
        id: 'component.admin.table.edit'
      }),
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: renderEdit
    },
    {
      title: formatMessage({
        id: 'component.admin.table.delete'
      }),
      dataIndex: 'delete',
      key: 'delete',
      width: 50,
      render: renderDelete
    }
  ]
}
