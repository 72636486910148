export enum Routes {
  Login = '/',
  UserInfoDashBoard = 'user-info-dashboard',
  UserInfoMoreData = 'user-info-moreData',
  Home = '/contact-center',
  Admin = 'admin'
}

export const FullRoutes = {
  Login: `${Routes.Login}`,
  UserInfoDashBoard: `${Routes.Home}/${Routes.UserInfoDashBoard}`,
  UserInfoMoreData: `${Routes.Home}/${Routes.UserInfoMoreData}`,
  Home: `${Routes.Home}`,
  Admin: `${Routes.Home}/${Routes.Admin}`
}

export const RoutesToSidebarKeys = {
  [FullRoutes.UserInfoDashBoard]: '1',
  [FullRoutes.UserInfoMoreData]: '2',
  [FullRoutes.Home]: '3',
  [FullRoutes.Admin]: '4'
}

export const FullRoutesToPageNames = {
  [FullRoutes.UserInfoDashBoard]: 'user-info-dashboard',
  [FullRoutes.UserInfoMoreData]: 'user-info-more-data',
  [FullRoutes.Home]: 'home',
  [FullRoutes.Admin]: 'admin'
}
