import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Typography } from 'antd'
import { LikeOutlined, LikeFilled } from '@ant-design/icons'
import classNames from 'classnames'
import Content, { Props as ContentProps } from 'components/common/Content'
import { FullRoutesToPageNames } from 'routes/constants'
import { likeSection } from 'redux/actions/sections-likes'
import { SECTION_IDS } from 'redux/reducers/sections-likes/types'
import { RootState } from 'redux/types/store'
import { isSectionLiked } from 'redux/selectors/likes'
import styles from './styles.module.css'

const { Title } = Typography

interface BoxProps extends ContentProps {
  sectionId: SECTION_IDS
  children: React.ReactNode
  extraTitleClassNames?: string
  shouldRenderLikeSection?: boolean
  extraWrapperClassNames?: string
  extraBodyClassNames?: string
  customHeaderComponent?: React.ReactNode
}

const Box: React.FC<BoxProps> = ({
  sectionId,
  children,
  shouldRenderLikeSection,
  extraWrapperClassNames,
  extraBodyClassNames,
  extraTitleClassNames,
  isLoading,
  loaderExtraClassName,
  noDataExtraClassName,
  noDataText,
  shouldRenderNoData,
  customLoader,
  customHeaderComponent
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const onLike = useCallback(() => {
    const page = FullRoutesToPageNames[pathname]

    dispatch(likeSection({ section: sectionId, page }))
  }, [dispatch, pathname, sectionId])

  const title = formatMessage({
    id: `component.user-info.${sectionId}.title`
  })

  const isLiked = useSelector((state: RootState) =>
    isSectionLiked(state, sectionId)
  )

  const renderLikeSection =
    shouldRenderLikeSection && !isLoading && !shouldRenderNoData

  const LikeSectionButton = isLiked ? (
    <LikeFilled
      className={classNames(styles.filledLikeButton, styles.likeButton)}
    />
  ) : (
    <LikeOutlined onClick={onLike} className={styles.likeButton} />
  )

  return (
    <div className={classNames(styles.wrapper, extraWrapperClassNames)}>
      <div className={styles.header}>
        <div className={styles.headWrapper}>
          <Title
            level={4}
            className={classNames(styles.title, extraTitleClassNames)}
          >
            {title}
          </Title>
          {customHeaderComponent}
          {renderLikeSection && LikeSectionButton}
        </div>
      </div>
      <div className={classNames(styles.body, extraBodyClassNames)}>
        <Content
          isLoading={isLoading}
          loaderExtraClassName={loaderExtraClassName}
          noDataExtraClassName={noDataExtraClassName}
          noDataText={noDataText}
          shouldRenderNoData={shouldRenderNoData}
          customLoader={customLoader}
        >
          {children}
        </Content>
      </div>
    </div>
  )
}

export default Box
