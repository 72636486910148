import classNames from 'classnames'
import DeviceUsage from 'components/UserInfo/Dashboard/DeviceUsage'
import useFetchUserInfoData from 'components/common/hooks/use-fetch-user-info-data'
import SignOnArticle from 'components/UserInfo/Dashboard/SignOnArticle'
import SubInfo from 'components/UserInfo/Dashboard/SubInfo'
import ReaderEngagement from 'components/UserInfo/Dashboard/ReaderEngagement'
import MostRead from 'components/UserInfo/Dashboard/MostRead'
import styles from './styles.module.css'

const UserInfo: React.FC = () => {
  useFetchUserInfoData()

  return (
    <div className={styles.container}>
      <div className={classNames(styles.smallSection)}>
        <SubInfo />
        <SignOnArticle />
      </div>
      <div className={styles.containerChilds}>
        <ReaderEngagement />
      </div>
      <div className={classNames(styles.smallSection)}>
        <DeviceUsage />
        <MostRead />
      </div>
    </div>
  )
}

export default UserInfo
