import { Loaders, LoadersStateType } from 'redux/reducers/loaders/types'

export const LoadersInitialState: LoadersStateType = {
  [Loaders.Cpn]: false,
  [Loaders.Home]: false,
  [Loaders.UserInfo]: false,
  [Loaders.UserInfoDeviceUsage]: false,
  [Loaders.MoreDataLifeTimeOffers]: false,
  [Loaders.MoreDataTimesPlusRedemption]: false,
  [Loaders.Admin]: false
}
