import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getCustomerStats } from 'redux/selectors/userInfo'
import Flag from 'components/common/Flag'
import ChurnScore from 'components/UserInfo/Dashboard/SubInfo/ChurnScore'
import Stat from 'components/common/Stat'
import { convertTenureMonthsToYears } from 'components/UserInfo/Dashboard/SubInfo/utils'
import styles from './style.module.css'

const SubStats: React.FC = () => {
  const { formatMessage } = useIntl()

  const {
    mpCode,
    tenureMonths,
    churnBuckets,
    churnSegmentExplanation,
    appDownloadedFlag,
    timesPlusRedemptionFlag
  } = useSelector(getCustomerStats)

  const tenure = convertTenureMonthsToYears(tenureMonths)

  const tenureLabel = formatMessage({
    id: 'component.user-info.customerStats.tenure'
  })

  const mpCodeLabel = formatMessage({
    id: 'component.user-info.customerStats.mpCode'
  })

  const appDownloadedFlagLabel = formatMessage({
    id: 'component.user-info.customerStats.appDownloaded'
  })

  const timesPlusRedemptionFlagLabel = formatMessage({
    id: 'component.user-info.customerStats.timesPlusEngagement'
  })

  return (
    <div className={styles.container}>
      <Stat
        extraClassName={styles.stat}
        label={tenureLabel}
        value={tenure || ''}
      />
      <Stat extraClassName={styles.stat} label={mpCodeLabel} value={mpCode} />
      <Flag label={appDownloadedFlagLabel} isChecked={appDownloadedFlag} />
      <Flag
        label={timesPlusRedemptionFlagLabel}
        isChecked={timesPlusRedemptionFlag}
      />
      <ChurnScore value={churnBuckets} description={churnSegmentExplanation} />
    </div>
  )
}

export default SubStats
