import Header from 'components/Home/Header'
import Sections from 'components/Home/Sections'

const Home: React.FC = () => (
  <div>
    <Header />
    <Sections />
  </div>
)

export default Home
