import { PrintVoucher } from 'redux/reducers/userInfo/types'
import { BoxColors } from 'components/UserInfo/Dashboard/ReaderEngagement/ActivityCalendar/ActivityCalendarBody/DaysBoxes/DayBox/constants'

export const getDayColor = (data?: PrintVoucher): string => {
  if (!data) {
    return BoxColors.Gray
  }

  return data.overRedemptionFlag ? BoxColors.Red : BoxColors.Green
}
