import {
  ApiRouteNames,
  ApiRoutePaths,
  ApiRoutesType,
  HttpContentTypeHeaderValues,
  HTTPHeadersType,
  HTTPMethods
} from 'api/types'

const ApiRoutes: ApiRoutesType = {
  [ApiRouteNames.GetSurvey]: {
    url: ApiRoutePaths.Survey,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetSectionTopArticles]: {
    url: ApiRoutePaths.SectionTopArticles,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetSignOnArticle]: {
    url: ApiRoutePaths.SignOnArticle,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetCustomerStats]: {
    url: ApiRoutePaths.CustomerStats,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetCustomerDailySessions]: {
    url: ApiRoutePaths.CustomerDailySessions,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetUserInfo]: {
    url: ApiRoutePaths.UserInfo,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetTimesPlusOffers]: {
    url: ApiRoutePaths.TimesPlusOffers,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetDeviceUsage]: {
    url: ApiRoutePaths.DeviceUsage,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetTimesPlusRedemption]: {
    url: ApiRoutePaths.TimesPlusRedemption,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.SaveCpnSearch]: {
    url: ApiRoutePaths.SaveCpnSearch,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.SaveLoginData]: {
    url: ApiRoutePaths.SaveLoginData,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.SaveSectionLike]: {
    url: ApiRoutePaths.SaveSectionLike,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetUsersStats]: {
    url: ApiRoutePaths.UsersStats,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetUsers]: {
    url: ApiRoutePaths.Users,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.CreateUser]: {
    url: ApiRoutePaths.CreateUser,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.UpdateUser]: {
    url: ApiRoutePaths.UpdateUser,
    method: HTTPMethods.PUT,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.DeleteUser]: {
    url: ApiRoutePaths.DeleteUser,
    method: HTTPMethods.DELETE,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON
    }
  }
}

export default ApiRoutes
