export const sectionFilterOptions = [
  { label: 'News', value: 'News' },
  { label: 'World', value: 'World' },
  { label: 'Comment', value: 'Comment' },
  { label: 'Business', value: 'Business' },
  { label: 'Sport', value: 'Sport' },
  { label: 'Times2', value: 'Times2' },
  { label: 'Register', value: 'Register' },
  { label: 'Puzzles', value: 'Puzzles' },
  { label: 'Scotland', value: 'Scotland' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Politics', value: 'Politics' },
  { label: 'Business & Finance', value: 'Business & Finance' },
  { label: 'Society', value: 'Society' },
  { label: 'Arts & Culture', value: 'Arts & Culture' },
  { label: 'Life & Style', value: 'Life & Style' },
  { label: 'Travel', value: 'Travel' },
  { label: 'Environment', value: 'Environment' }
]

export const defaultSectionFilterValue = 'News'

export const defaultLookBackDaysValue = 6
