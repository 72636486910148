import { all, takeEvery, put, call } from 'redux-saga/effects'
import api from 'api'
import { handleError } from 'redux/actions/errors'
import { SAVE_CPN_SEARCH } from 'redux/actions/cpn/constants'
import { resetLikes } from 'redux/actions/sections-likes'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* handleSaveCpnSearch({ payload: { cpn } }: any) {
  try {
    yield call(api.saveCpnSearch, {
      body: { cpn }
    })
    yield put(resetLikes())
  } catch (err: any) {
    yield put(handleError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* saveCpnSearch() {
  yield all([takeEvery(SAVE_CPN_SEARCH, handleSaveCpnSearch)])
}
