import { Outlet, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import classNames from 'classnames'
import Sidebar from 'components/common/LayoutContent/Sidebar'
import Header from 'components/common/LayoutContent/Header'
import CpnModal from 'components/common/CpnModal'
import useSetCpnInQuery from 'components/common/hooks/use-set-cpn-in-query'
import styles from './styles.module.css'
import { FullRoutes } from 'routes/constants'

const { Content } = Layout

const LayoutContent: React.FC = () => {
  useSetCpnInQuery()
  const { pathname } = useLocation()

  const isUserInfoPage = pathname === FullRoutes.UserInfoDashBoard

  return (
    <>
      <Layout>
        <Sidebar />
        <Layout className={styles.siteLayout}>
          <Header />
          <Content
            className={classNames({
              [styles.content]: true,
              [styles.userInfo]: isUserInfoPage
            })}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <CpnModal />
    </>
  )
}

export default LayoutContent
