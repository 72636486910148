import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Layout, Menu } from 'antd'
import { FullRoutes, RoutesToSidebarKeys } from 'routes/constants'
import { menuConfig } from 'components/common/LayoutContent/Sidebar/config'
import { MenuConfigType } from 'components/common/LayoutContent/Sidebar/types'
import Logo from 'components/common/LayoutContent/Sidebar/Logo'
import useIsAdmin from 'components/common/hooks/use-is-admin'

const { Sider } = Layout

const Sidebar: React.FC = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()

  const renderMenuItems = useCallback(
    ({ key, icon, to, labelKey, childrenItems }: MenuConfigType): any => {
      if (key === RoutesToSidebarKeys[FullRoutes.Admin] && !isAdmin) {
        return null
      }

      const label = formatMessage({
        id: labelKey
      })

      const onClick = (): void => {
        if (to) {
          navigate(to)
        }
      }

      const children = childrenItems && childrenItems?.map(renderMenuItems)

      return {
        key,
        icon,
        label,
        onClick,
        children
      }
    },
    [formatMessage, isAdmin, navigate]
  )

  const menuItems = useMemo(
    () => menuConfig.map(renderMenuItems),
    [renderMenuItems]
  )

  const selectedMenuItem = RoutesToSidebarKeys[pathname]

  return (
    <Sider trigger={null}>
      <Logo />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[RoutesToSidebarKeys[pathname] || '1']}
        activeKey={selectedMenuItem}
        selectedKeys={[selectedMenuItem]}
        items={menuItems}
      />
    </Sider>
  )
}

export default Sidebar
