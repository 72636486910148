import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { AdminInitialState } from 'redux/reducers/admin/constants'
import { UsersData, UsersStats } from 'redux/actions/admin/types'
import mapUsers from 'redux/mappers/mapUsers'

export const adminSlice = createSlice({
  name: ReducersType.Admin,
  initialState: AdminInitialState,
  reducers: {
    consumeUsers: (state, { payload }: PayloadAction<UsersData>) => ({
      ...state,
      users: mapUsers(payload, state.usersStats)
    }),
    consumeUsersStats: (state, { payload }: PayloadAction<UsersStats[]>) => ({
      ...state,
      users: mapUsers(state.users, payload),
      usersStats: payload
    }),
    resetAdminState: (state) => ({
      ...state,
      users: []
    })
  }
})
