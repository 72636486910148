import { createAction } from '@reduxjs/toolkit'
import {
  FETCH_SECTION_TOP_ARTICLES,
  CONSUME_ARTICLES,
  RESET_SECTION_ARTICLES,
  SELECT_SECTION_FILTER_VALUE,
  SELECT_LOOK_BACK_DATE,
  RESET_LOOK_BACK_DATE,
  RESET_SECTION_FILTER_VALUE
} from 'redux/actions/home/constants'
import {
  ConsumeArticlesAction,
  SelectLookBackFilterDatePayload,
  SelectSectionFilterValuePayload
} from 'redux/actions/home/types'

export const fetchSectionTopArticles = createAction(FETCH_SECTION_TOP_ARTICLES)

export const consumeArticles = createAction<ConsumeArticlesAction>(
  CONSUME_ARTICLES,
  (articles) => ({
    payload: {
      articles
    }
  })
)

export const resetTopArticles = createAction(RESET_SECTION_ARTICLES)
export const selectSectionFilterValue =
  createAction<SelectSectionFilterValuePayload>(SELECT_SECTION_FILTER_VALUE)

export const selectLookBackDate = createAction<SelectLookBackFilterDatePayload>(
  SELECT_LOOK_BACK_DATE
)

export const resetLookBackDate = createAction(RESET_LOOK_BACK_DATE)

export const resetSectionFilterValue = createAction(RESET_SECTION_FILTER_VALUE)
