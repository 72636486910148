import Header from 'components/Admin/Header'
import UsersTable from 'components/Admin/UserTable'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchUsersStats } from 'redux/actions/admin'

const Admin: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsersStats())
  }, [dispatch])

  return (
    <>
      <Header />
      <UsersTable />
    </>
  )
}

export default Admin
